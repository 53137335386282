import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { useMutation, useQueryClient } from '@tanstack/react-query'

interface useDeleteRewardsCatalogRewardsParams {
  id: number | null | undefined
}

interface useDeleteRewardsCatalogRewardsProps {
  onSuccess?: (
    data: undefined,
    variables: useDeleteRewardsCatalogRewardsParams
  ) => void
  onError?: (
    error: Error,
    variables: useDeleteRewardsCatalogRewardsParams
  ) => void
}

export const useDeleteRewardsCatalogRewards = ({
  onSuccess,
  onError,
}: useDeleteRewardsCatalogRewardsProps) => {
  const deleteRewardsCatalogRewards = useTypedApiClient({
    path: '/admin/rewards-catalog-rewards/{id}',
    method: 'delete',
  })
  const queryClient = useQueryClient()

  return useMutation<any, Error, useDeleteRewardsCatalogRewardsParams>({
    mutationFn: async ({ id }: useDeleteRewardsCatalogRewardsParams) => {
      await deleteRewardsCatalogRewards({ id: Number(id) })
    },
    onSuccess: async (data, variables) => {
      await queryClient.invalidateQueries({
        queryKey: ['/admin/rewards-catalogs'],
      })
      onSuccess?.(data, variables)
    },
    onError,
  })
}
