import { useQuery } from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'

export const useGetCampaignDetailsById = ({ id }: { id: number }) => {
  const get = useTypedApiClient({
    path: '/admin/campaigns/details/{id}',
    method: 'get',
  })
  return useQuery({
    queryKey: ['/admin/campaigns/details/{id}', id],
    queryFn: async () => {
      const { data } = await get({ id })
      return data
    },
  })
}
