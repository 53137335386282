import { SettingsDetailsHeader } from './SettingsDetailsHeader'
import { SettingsDetailsPageEULA } from './SettingsDetailsPageEULA'

export const SettingsDetailsPage = () => {
  return (
    <SettingsDetailsHeader currentTab={'/Settings/SettingsDetailsPageEULA'}>
      <SettingsDetailsPageEULA />
    </SettingsDetailsHeader>
  )
}
