import {
  InputAdornment,
  type StandardTextFieldProps,
  TextField,
  Stack,
} from '@mui/material'
import { useState } from 'react'
import { debounce } from 'lodash'
import SearchIcon from '@mui/icons-material/Search'
import { type components } from 'api/playerPayback/api'

// Define a type that either refers to the schemas from components or a custom type
type SchemaType<T> = T extends keyof components['schemas']
  ? components['schemas'][T]
  : T

interface GridSearchFieldProps<T> extends StandardTextFieldProps {
  data: Array<SchemaType<T>>
  handleFilteredData: (filteredData: Array<SchemaType<T>>) => void
}

export const GridSearchField = <T,>({
  data,
  handleFilteredData,
  ...props
}: GridSearchFieldProps<T>) => {
  const [searchTerm, setSearchTerm] = useState('')

  const debouncedHandleSearchChange = debounce((searchTerm: string) => {
    const filteredData = data.filter((item) => {
      if (typeof item !== 'object' || item === null) return false

      // Handle entries in a more generic way without strict index signature
      const itemEntries = Object.entries(item) as Array<
        [
          string,
          (
            | string
            | number
            | { name?: string; standardName?: string }
            | null
            | undefined
          ),
        ]
      >

      // Check if any value in the item matches the formatted search term
      return itemEntries.some(([, value]) => {
        if (typeof value === 'string') {
          // Check if the value matches the formatted search term
          return value.toLowerCase().includes(searchTerm.toLowerCase())
        } else if (typeof value === 'number') {
          // Convert number to string and check
          return value.toString().includes(searchTerm)
        } else if (
          typeof value === 'object' &&
          value !== null &&
          ('name' in value || 'standardName' in value)
        ) {
          // Check if the object has a 'name' field and if it matches the search term
          return (
            (typeof value.name === 'string' &&
              value.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (typeof value.standardName === 'string' &&
              value.standardName
                .toLowerCase()
                .includes(searchTerm.toLowerCase()))
          )
        }
        return false
      })
    })

    handleFilteredData(filteredData)
  }, 500)

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value
    setSearchTerm(searchTerm)
    debouncedHandleSearchChange(searchTerm)
  }

  return (
    <Stack
      direction={{ md: 'row', sm: 'column', xs: 'column' }}
      gap={{ md: 0, sm: 2, xs: 2 }}
      mb={{ md: 4, sm: 2, xs: 2 }}
      justifyContent={'space-between'}
    >
      <TextField
        {...props}
        value={searchTerm}
        onChange={handleSearchChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  )
}
