import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { type Campaign } from 'types/api'
import { useMutation, useQueryClient } from '@tanstack/react-query'

interface UsePutRewardProps {
  id: number
  onSuccess?: (data: Campaign, variables: Campaign) => void
  onError?: (error: Error, variables: Campaign) => void
}

export const usePutCampaign = ({
  id,
  onSuccess,
  onError,
}: UsePutRewardProps) => {
  const put = useTypedApiClient({
    path: '/admin/campaigns/{id}',
    method: 'put',
  })
  const queryClient = useQueryClient()

  return useMutation<any, Error, Campaign>({
    mutationFn: async (data: Campaign) => {
      const response = await put({ ...data, id })
      return response.data
    },
    onSuccess: async (data, variables) => {
      await queryClient.invalidateQueries({
        queryKey: ['/admin/campaigns/{id}', id],
      })
      onSuccess?.(data, variables)
    },
    onError,
  })
}
