import { isPresent } from '@jjvgaming/player-payback-library'
import { date, number, object, string } from 'yup'
import { Alert, Button, Grid, InputLabel, TextField } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormFieldErrorMessage } from 'components/FormFieldErrorMessage'
import { ActivityButton } from 'components/ActivityButton'
import { Modal } from 'components/Modal/Modal'
import { useLayout } from 'hooks/useLayout'
import { ModalFullHeader } from 'components/Modal/ModalFullHeader'
import { ModalFullBody } from 'components/Modal/ModalFullBody'
import { ModalFullFooter } from 'components/Modal/ModalFullFooter'
import { type Campaign } from 'types/api'
import { type RequiredId } from 'utils/typeUtils'
import { AdornedDateTimeField } from 'components/AdornedDateTimeField/AdornedDateTimeField'
import { isNil } from 'lodash'
import { formatISO, isAfter, parseISO } from 'date-fns'

interface EditCampaignDetailsProps {
  campaign: RequiredId<Campaign>
  isModalOpen: boolean
  toggleModalIsOpen: () => void
  updateIsActive: boolean
  onSubmitCampaign: (campaign: RequiredId<Campaign>) => void
}

export const EditCommunicationDetailsModal = ({
  campaign,
  updateIsActive,
  isModalOpen,
  toggleModalIsOpen,
  onSubmitCampaign,
}: EditCampaignDetailsProps) => {
  const { isMobile } = useLayout()
  const heightModal = isMobile ? '100%' : '75%'
  const deliveryDate = getDeliveryDate(campaign)

  const CampaignDetailsSchema = object({
    Campaign: object({
      id: number(),
      subject: string()
        .required('Subject is a required field')
        .max(50, 'Subject cannot exceed 50 characters'),
      body: string()
        .required('Message Content is a required field')
        .max(500, 'Message Content cannot exceed 500 characters'),
      licensedEstablishmentId: number(),
      deliveryDate: date()
        .typeError('Must enter a valid Delivery Date')
        .required('Must enter a Delivery Date')
        .test(
          'DayInPast',
          'Scheduled Date should not be a date in the past',
          (value) => {
            return !isNil(deliveryDate) &&
              deliveryDate.toISOString() !== value.toISOString()
              ? isAfter(value, new Date())
              : true
          }
        ),
    }),
  })

  const formMethods = useForm({
    resolver: yupResolver(CampaignDetailsSchema),
    mode: 'all',
    defaultValues: {
      Campaign: {
        deliveryDate: getDeliveryDate(campaign),
        body: campaign.body ? campaign.body : '',
        subject: campaign.subject ? campaign.subject : '',
      },
    },
  })

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = formMethods

  const onCancel = () => {
    toggleModalIsOpen()
    reset()
  }

  const handleSubmitWrapper = handleSubmit((data) => {
    onSubmitCampaign({
      ...campaign,
      id: campaign.id,
      subject: data.Campaign.subject,
      body: data.Campaign.body,
      licensedEstablishments: campaign.licensedEstablishments,
      deliveryDate: formatISO(data.Campaign.deliveryDate),
    })
  })

  return (
    <Modal
      isOpen={isModalOpen}
      toggleIsOpen={() => {}}
      sx={{
        width: isMobile ? '100%' : '55%',
        height: heightModal,
      }}
    >
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmitWrapper}>
          <ModalFullHeader
            title="Edit Message Details"
            subTitle={`${campaign.type}`}
            toggleIsOpen={toggleModalIsOpen}
          />
          <ModalFullBody heightModal={heightModal}>
            <Grid container>
              <Grid container>
                {Object.keys(errors).length > 0 && (
                  <Grid item xs={12} pb={4}>
                    <Alert severity="error">
                      Please correct errors to continue
                    </Alert>
                  </Grid>
                )}

                <Grid item xs={12} md={6} pt={{ md: 2 }}>
                  <InputLabel
                    required
                    htmlFor="Message.name"
                    sx={{ fontSize: '16px', fontWeight: '100', width: '100%' }}
                  >
                    Subject Line
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    required
                    error={isPresent(errors.Campaign?.subject)}
                    placeholder="Enter Subject Line"
                    {...register('Campaign.subject')}
                    defaultValue={campaign.subject}
                    helperText="50 charcters max"
                  />
                  {errors.Campaign?.subject?.message && (
                    <FormFieldErrorMessage
                      message={errors.Campaign.subject.message}
                    />
                  )}
                </Grid>
              </Grid>

              <Grid container pt={4}>
                <Grid item xs={12} md={6} pt={{ md: 2 }}>
                  <InputLabel
                    htmlFor="Message.body"
                    required
                    sx={{ fontSize: '16px', fontWeight: '100' }}
                  >
                    Message Content
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    multiline
                    rows={5}
                    required
                    error={isPresent(errors.Campaign?.body)}
                    placeholder="Enter description of Message"
                    {...register('Campaign.body')}
                    defaultValue={campaign.body}
                    sx={{ width: '100%' }}
                    helperText="500 charcters max"
                  />
                  {errors.Campaign?.body?.message && (
                    <FormFieldErrorMessage
                      message={errors.Campaign.body.message}
                    />
                  )}
                </Grid>
              </Grid>

              <Grid container pt={4}>
                <Grid item xs={12} md={6} pt={{ md: 2 }}>
                  <InputLabel
                    htmlFor="Campaign.deliveryDate"
                    required
                    sx={{ fontSize: '16px', fontWeight: '100' }}
                  >
                    Scheduled Date
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={6}>
                  <AdornedDateTimeField
                    name="Campaign.deliveryDate"
                    control={control}
                  />
                  {errors.Campaign?.deliveryDate?.message && (
                    <FormFieldErrorMessage
                      message={errors.Campaign?.deliveryDate.message}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </ModalFullBody>
          <ModalFullFooter>
            <Button variant="text" onClick={() => onCancel()}>
              Cancel
            </Button>
            <ActivityButton
              disabled={!!errors.Campaign}
              active={!!updateIsActive}
              type="submit"
              variant="contained"
            >
              Save
            </ActivityButton>
          </ModalFullFooter>
        </form>
      </FormProvider>
    </Modal>
  )
}

const getDeliveryDate = (campaign: Campaign) => {
  if (isNil(campaign.deliveryDate)) {
    return undefined
  }

  return parseISO(campaign.deliveryDate)
}
