import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { Page } from 'components/Shared/Page'
import { useParams } from 'react-router-dom'
import { useGetLicensedEstablishmentById } from 'hooks/api/LicensedEstablishment/useGetLicensedEstablishmentById'
import { LicensedEstablishmentDetailsHeader } from '../LicensedEstablishmentDetailsHeader'
import { PromotionsPageSection } from 'pages/Promotions/PromotionsPageSection'
import { ErrorIndicator } from 'components/ErrorIndicator/ErrorIndicator'
import { Box } from '@mui/material'

export const LicensedEstablishmentDetailsSpecialsEventsPage = () => {
  const params = useParams()
  const licensedEstablishmentId = Number(params.id)

  const getLicensedEstablishmentQuery = useGetLicensedEstablishmentById(
    licensedEstablishmentId
  )

  if (getLicensedEstablishmentQuery.isPending) {
    return <ActivityIndicator />
  }

  if (getLicensedEstablishmentQuery.isError) {
    return <ErrorIndicator />
  }

  const licensedEstablishment = getLicensedEstablishmentQuery.data

  if (!licensedEstablishment.id) {
    return <Box>Licensed Establishment Id must not be null</Box>
  }

  return (
    <Page
      header={
        <LicensedEstablishmentDetailsHeader
          licensedEstablishment={licensedEstablishment}
          currentTab={`/LicensedEstablishments/${licensedEstablishment.id}/LicensedEstablishmentDetailsSpecialsEvents`}
        />
      }
    >
      <PromotionsPageSection type={'Location'} id={licensedEstablishmentId} />
    </Page>
  )
}
