import { type UseQueryResult, useQuery } from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { type components } from 'src/api/playerPayback/api'
export const useGetPointsLedgersByRewardProgramId = ({
  rewardProgramId,
  currentPage,
  pageSize,
  numOfDays,
  searchTerm,
  sortColumnName,
  sortOrder,
  enabled = true,
  types = [
    'CheckInPoint',
    'CheckInPointMultiplier',
    'ManualPoint',
    'MilestoneBirthday',
    'MilestoneWelcome',
    'MilestoneNextTier',
  ],
}: {
  rewardProgramId: number
  currentPage: number
  pageSize: number
  numOfDays: number
  searchTerm: string
  sortColumnName: string
  sortOrder: string
  enabled?: boolean
  types?: Array<components['schemas']['TransactionType']>
}): UseQueryResult<
  components['schemas']['FullPointsLedgerEntryDTOPageResultDTO']
> => {
  const get = useTypedApiClient({
    path: '/admin/rewards-programs/{id}/points-ledgers/{currentPage}/{pageSize}',
    method: 'get',
  })
  return useQuery({
    queryKey: [
      '/admin/rewards-programs/{id}/points-ledgers/{currentPage}/{pageSize}',
      rewardProgramId,
      currentPage,
      pageSize,
      numOfDays,
      searchTerm,
      sortColumnName,
      sortOrder,
    ],
    queryFn: async () => {
      const { data } = await get({
        id: rewardProgramId,
        currentPage,
        pageSize,
        types,
        numOfDays,
        searchTerm,
        sortColumnName,
        sortOrder,
      })
      return data
    },
    enabled,
  })
}
