import {
  DataGrid,
  type DataGridProps,
  type GridValidRowModel,
} from '@mui/x-data-grid'
import { useLayout } from 'hooks/useLayout'
import { MobileDataTable } from 'components/DataTable/MobileDataTable'
import { isEmpty } from 'lodash'
import { NoDataIndicator } from 'components/NoDataIndicator/NoDataIndicator'
import { colorPrimitives } from 'components/Theme'

export const DataTablePaging = <RowModelT extends GridValidRowModel>({
  sortFieldName,
  sort,
  totalCount,
  noDataMessage = 'No data found',
  forceDesktopTableInMobile = false,
  emptyDataImageSource,
  ...props
}: DataGridProps<RowModelT> & {
  sortFieldName?: string
  sort?: 'asc' | 'desc'
  totalCount: number
  noDataMessage?: string
  forceDesktopTableInMobile?: boolean
  emptyDataImageSource?: string
}) => {
  const { isMobile } = useLayout()
  const {
    columns,
    rows,
    onRowClick,
    paginationModel,
    onPaginationModelChange,
    onSortModelChange,
    sortModel,
    checkboxSelection,
    onRowSelectionModelChange,
    rowSelectionModel,
  } = props

  if (isEmpty(rows)) {
    return (
      <NoDataIndicator
        noDataMessage={noDataMessage}
        imageSource={emptyDataImageSource}
      />
    )
  }

  if (isMobile && !forceDesktopTableInMobile) {
    return (
      <MobileDataTable
        columns={columns}
        rows={rows}
        onRowClick={onRowClick}
        checkboxSelection={checkboxSelection}
        rowSelectionModel={rowSelectionModel}
        onRowSelectionModelChange={onRowSelectionModelChange}
      />
    )
  }

  return (
    <DataGrid<RowModelT>
      sx={{
        '& .MuiDataGrid-row:hover': {
          backgroundColor: colorPrimitives.grayDataTableHover,
        },

        '& .MuiDataGrid-columnHeaderTitleContainer .MuiDataGrid-iconButtonContainer':
          {
            marginLeft: '2px',
            visibility: 'visible',
            width: 'auto',
            opacity: 'inherit',
          },

        '& .MuiDataGrid-row, & .MuiDataGrid-cell:first-of-type': {
          color: colorPrimitives.black,
        },
      }}
      autoHeight
      checkboxSelection={checkboxSelection}
      pagination
      paginationModel={paginationModel}
      paginationMode="server"
      onPaginationModelChange={onPaginationModelChange}
      rowCount={totalCount}
      disableRowSelectionOnClick
      disableColumnMenu
      sortModel={sortModel}
      onSortModelChange={onSortModelChange}
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 25 },
        },
      }}
      hideFooter={totalCount <= 25}
      pageSizeOptions={[25, 50, 100]}
      {...props}
    />
  )
}
