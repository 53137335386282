import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormGroup,
} from '@mui/material'
import { grey } from '@mui/material/colors'
import { useLayout } from 'hooks/useLayout'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import {
  LEUserRoleAndPermissionSchema,
  LeUserRoles,
} from './LEUserRoleAndPermissionSchema'
import { yupResolver } from '@hookform/resolvers/yup'
import { usePutLicensedEstablishmentUser } from 'hooks/api/LicensedEstablishmentUser/usePutLicensedEstablishmentUser'
import {
  type UpdateLicensedEstablishmentUserRequest,
  type FullLicensedEstablishmentUserDTO,
} from 'types/api'
import { useSnackbar } from 'stores/useSnackbar'
import { every, remove } from 'lodash'
import { PermissionControllerCheckbox } from './PermissionControllerCheckbox'
import { ActivityButton } from 'components/ActivityButton'

export type permissionFormDataType =
  | 'type'
  | 'reportsFinancialPermission'
  | 'reportsNonFinancialPermission'
  | 'reportsAccessToPIIPermission'
  | 'reportsDownloadPIIPermission'
  | 'patronsAccountsPermission'
  | 'rewardsPermission'
  | 'promotionsPermission'
  | 'locationsPermission'
  | 'leUsersPermission'
  | 'liveAtLocationViewPermission'
  | 'sweepstakesPermission'
  | 'allPermissions'
  | 'allReports'

interface EditLeUserRoleAndPermissionsModalProps {
  isModalOpen: boolean
  onClose: () => void
  leUser: FullLicensedEstablishmentUserDTO
}

const getTypeLabel = (type: string | undefined) => {
  switch (type) {
    case 'LimitedLiveAtLocation':
      return 'Limited'
    case 'SalesAccountManager':
      return 'Sales Account Manager'
    case 'SalesServiceRep':
      return 'Sales Service Rep'
    default:
      return type ?? ''
  }
}

const isEverythingSelectedForDefaultValue = (
  leUser: FullLicensedEstablishmentUserDTO
): boolean => {
  const permissions = remove(Object.entries(leUser), ([key]) => {
    return key.includes('Permission')
  }).map(([, value]) => value)
  return every(permissions, (value) => value === true)
}

const areAllReportsSelectedForDefaultValue = (
  leUser: FullLicensedEstablishmentUserDTO
): boolean => {
  return (
    leUser.reportsFinancialPermission && leUser.reportsNonFinancialPermission
  )
}

const generateDefaultValues = (leUser: FullLicensedEstablishmentUserDTO) => {
  return {
    allPermissions: isEverythingSelectedForDefaultValue(leUser),
    allReports: areAllReportsSelectedForDefaultValue(leUser),
    leUsersPermission: leUser.leUsersPermission,
    liveAtLocationViewPermission: leUser.liveAtLocationViewPermission,
    locationsPermission: leUser.locationsPermission,
    patronsAccountsPermission: leUser.patronsAccountsPermission,
    promotionsPermission: leUser.promotionsPermission,
    reportsFinancialPermission: leUser.reportsFinancialPermission,
    reportsNonFinancialPermission: leUser.reportsNonFinancialPermission,
    reportsAccessToPIIPermission: leUser.reportsAccessToPIIPermission,
    reportsDownloadPIIPermission: leUser.reportsDownloadPIIPermission,
    rewardsPermission: leUser.rewardsPermission,
    sweepstakesPermission: leUser.sweepstakesPermission,
    type: leUser.type,
  }
}

export const EditLeUserRoleAndPermissionsModal = ({
  isModalOpen,
  onClose,
  leUser,
}: EditLeUserRoleAndPermissionsModalProps) => {
  const { setMessage } = useSnackbar()
  const { isMobile } = useLayout()
  const formMethods = useForm({
    resolver: yupResolver(LEUserRoleAndPermissionSchema),
    mode: 'onSubmit',
    defaultValues: generateDefaultValues(leUser),
  })

  const { handleSubmit, setValue, getValues, reset, watch } = formMethods

  const myType = watch('type')

  const mutation = usePutLicensedEstablishmentUser({
    licensedEstablishmentUserId: leUser.id,
    onError: async () => {
      setMessage('An Error Occurred Updating Roles and Permissions', 'error', {
        vertical: 'top',
        horizontal: 'right',
      })
      onClose()
    },
    onSuccess: async (newLeUser) => {
      reset(generateDefaultValues(newLeUser))
      setMessage('Successfully updated Roles and Permissions', 'success', {
        vertical: 'top',
        horizontal: 'right',
      })
      onClose()
    },
  })

  const formIsEverythingSelected = (): boolean => {
    const values = getValues()
    delete values.allPermissions
    delete values.allReports
    return every(Object.values(values), (x) => x === true)
  }

  const formIsAllReportsSelected = (): boolean => {
    const values = getValues()
    return (
      values.reportsFinancialPermission && values.reportsNonFinancialPermission
    )
  }

  const handleChange = (key: permissionFormDataType) => {
    const currentValue = getValues(key)
    setValue(key, !currentValue)
    setValue('allPermissions', formIsEverythingSelected())
  }

  return (
    <Dialog
      fullScreen={isMobile}
      open={isModalOpen}
      onClose={() => {
        onClose()
      }}
      maxWidth={'xl'}
      fullWidth
      PaperProps={{ sx: { p: 0, maxWidth: '800px' } }}
    >
      <DialogTitle bgcolor={grey[50]} sx={{ p: 0 }}>
        <Box px={{ xs: 2, sm: 4 }} py={{ xs: 3, sm: 4 }}>
          <Typography variant="h3">Edit Roles and Permissions</Typography>
          <Typography variant="label-form" color={'text.secondary'}>
            Default permission level for new Corporate Accounts is{' '}
            <q>no access</q> until modified by an admin.
          </Typography>
        </Box>
      </DialogTitle>
      <form
        onSubmit={handleSubmit((data) => {
          const updateData = data
          delete updateData.allPermissions
          delete updateData.allReports
          const { email, firstName, lastName } = leUser
          const mutationRequest: UpdateLicensedEstablishmentUserRequest = {
            ...updateData,
            email,
            firstName,
            lastName,
          }
          mutation.mutate(mutationRequest)
        })}
      >
        <FormProvider {...formMethods}>
          <DialogContent
            sx={{ p: 0, zIndex: 0, overflow: 'auto', height: '60vh' }}
          >
            <Grid px={{ xs: 2, sm: 4 }} pb={{ xs: 3, sm: 4 }} container>
              <Grid item md={6} xs={12} pt={{ md: '32px', xs: '8px' }}>
                <Typography>Roles</Typography>
              </Grid>
              <Grid item md={6} xs={12} pt={{ md: '32px', xs: '8px' }}>
                <Controller
                  render={({ field }) => (
                    <RadioGroup
                      aria-label="type"
                      {...field}
                      onChange={(e) => {
                        const selectedRole = e.target.value as
                          | 'Owner'
                          | 'Staff'
                          | 'SalesAccountManager'
                          | 'LimitedLiveAtLocation'
                          | 'SalesServiceRep'
                        setValue('type', selectedRole)

                        if (selectedRole === 'LimitedLiveAtLocation') {
                          setValue('allPermissions', false)
                          setValue('allReports', false)
                          setValue('reportsFinancialPermission', false)
                          setValue('reportsNonFinancialPermission', false)
                          setValue('reportsAccessToPIIPermission', false)
                          setValue('reportsDownloadPIIPermission', false)
                          setValue('promotionsPermission', false)
                          setValue('locationsPermission', false)
                          setValue('leUsersPermission', false)
                          setValue('rewardsPermission', false)
                          setValue('patronsAccountsPermission', false)
                          setValue('liveAtLocationViewPermission', true)
                        }
                      }}
                    >
                      {LeUserRoles.map((type) => (
                        <FormControlLabel
                          key={type}
                          value={type}
                          control={<Radio />}
                          label={getTypeLabel(type)}
                        />
                      ))}
                    </RadioGroup>
                  )}
                  name={'type'}
                  control={formMethods.control}
                />
              </Grid>
              <Grid item md={6} xs={12} pt={{ md: '32px', xs: '8px' }}>
                <Typography>Permissions</Typography>
              </Grid>
              <Grid item md={6} xs={12} pt={{ md: '32px', xs: '8px' }}>
                <FormGroup>
                  {myType !== 'LimitedLiveAtLocation' && (
                    <PermissionControllerCheckbox
                      label="Select All"
                      name="allPermissions"
                      control={formMethods.control}
                      onChange={() => {
                        const newValue = !getValues('allPermissions')
                        setValue('allPermissions', newValue)
                        setValue('allReports', newValue)
                        setValue('reportsFinancialPermission', newValue)
                        setValue('reportsNonFinancialPermission', newValue)
                        setValue('reportsAccessToPIIPermission', newValue)
                        setValue('reportsDownloadPIIPermission', newValue)
                        setValue('promotionsPermission', newValue)
                        setValue('locationsPermission', newValue)
                        setValue('leUsersPermission', newValue)
                        setValue('rewardsPermission', newValue)
                        setValue('patronsAccountsPermission', newValue)
                        setValue('liveAtLocationViewPermission', newValue)
                        setValue('sweepstakesPermission', newValue)
                      }}
                    />
                  )}
                  {myType !== 'LimitedLiveAtLocation' && (
                    <FormGroup sx={{ pl: '32px' }}>
                      <PermissionControllerCheckbox
                        label="Reports"
                        name="allReports"
                        control={formMethods.control}
                        onChange={() => {
                          setValue('allReports', !getValues('allReports'))
                          setValue(
                            'reportsFinancialPermission',
                            !getValues('reportsFinancialPermission')
                          )
                          setValue(
                            'reportsNonFinancialPermission',
                            !getValues('reportsNonFinancialPermission')
                          )
                          setValue('allPermissions', formIsEverythingSelected())
                        }}
                      />
                      <FormGroup sx={{ pl: '32px' }}>
                        <PermissionControllerCheckbox
                          label="Financial"
                          name="reportsFinancialPermission"
                          control={formMethods.control}
                          onChange={() => {
                            handleChange('reportsFinancialPermission')
                            setValue(
                              'allPermissions',
                              formIsEverythingSelected()
                            )
                            setValue('allReports', formIsAllReportsSelected())
                          }}
                        />
                        <PermissionControllerCheckbox
                          label="Non-Financial"
                          name="reportsNonFinancialPermission"
                          control={formMethods.control}
                          onChange={() => {
                            handleChange('reportsNonFinancialPermission')
                            setValue(
                              'allPermissions',
                              formIsEverythingSelected()
                            )
                            setValue('allReports', formIsAllReportsSelected())
                          }}
                        />
                        <PermissionControllerCheckbox
                          label="Access to PII"
                          name="reportsAccessToPIIPermission"
                          control={formMethods.control}
                          onChange={() => {
                            handleChange('reportsAccessToPIIPermission')
                            setValue(
                              'allPermissions',
                              formIsEverythingSelected()
                            )
                            setValue('allReports', formIsAllReportsSelected())
                          }}
                        />
                        <PermissionControllerCheckbox
                          label="Download PII"
                          name="reportsDownloadPIIPermission"
                          control={formMethods.control}
                          onChange={() => {
                            handleChange('reportsDownloadPIIPermission')
                            setValue(
                              'allPermissions',
                              formIsEverythingSelected()
                            )
                            setValue('allReports', formIsAllReportsSelected())
                          }}
                        />
                      </FormGroup>

                      <PermissionControllerCheckbox
                        label="Specials & Events"
                        name="promotionsPermission"
                        control={formMethods.control}
                        onChange={() => {
                          handleChange('promotionsPermission')
                        }}
                      />
                      <PermissionControllerCheckbox
                        label="Locations"
                        name="locationsPermission"
                        control={formMethods.control}
                        onChange={() => {
                          handleChange('locationsPermission')
                        }}
                      />
                      <PermissionControllerCheckbox
                        label="J&J Connect Users"
                        name="leUsersPermission"
                        control={formMethods.control}
                        onChange={() => {
                          handleChange('leUsersPermission')
                        }}
                      />
                      <PermissionControllerCheckbox
                        label="Rewards"
                        name="rewardsPermission"
                        control={formMethods.control}
                        onChange={() => {
                          handleChange('rewardsPermission')
                        }}
                      />
                      <PermissionControllerCheckbox
                        label="Patrons"
                        name="patronsAccountsPermission"
                        control={formMethods.control}
                        onChange={() => {
                          handleChange('patronsAccountsPermission')
                        }}
                      />
                      <PermissionControllerCheckbox
                        label="Attendant"
                        name="liveAtLocationViewPermission"
                        control={formMethods.control}
                        onChange={() => {
                          handleChange('liveAtLocationViewPermission')
                        }}
                      />
                      <PermissionControllerCheckbox
                        label="Sweepstakes"
                        name="sweepstakesPermission"
                        control={formMethods.control}
                        onChange={() => {
                          handleChange('sweepstakesPermission')
                        }}
                      />
                    </FormGroup>
                  )}
                </FormGroup>

                {myType === 'LimitedLiveAtLocation' && (
                  <FormGroup>
                    <PermissionControllerCheckbox
                      label="Attendant"
                      name="liveAtLocationViewPermission"
                      control={formMethods.control}
                      onChange={() => {
                        handleChange('liveAtLocationViewPermission')
                      }}
                    />
                  </FormGroup>
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className="border-t">
            <Box className="flex flex-row justify-end py-4 md:px-8 sm:px-4">
              <Button
                onClick={() => {
                  reset()
                  onClose()
                }}
              >
                Cancel
              </Button>
              <ActivityButton
                active={mutation.isPending}
                variant="contained"
                type="submit"
              >
                Save
              </ActivityButton>
            </Box>
          </DialogActions>
        </FormProvider>
      </form>
    </Dialog>
  )
}
