import { useState } from 'react'
import { Button, Typography, Box } from '@mui/material'
import { colorPrimitives } from 'components/Theme'
import { SettingsModal } from './SettingsModal'
import { type FullCheckInPolicy, type RewardsProgram } from 'types/api'
import { GrantCheckInsDetails } from './GrantCheckInsDetails'
import { addMissingDays } from '../RewardsProgramDetailsUtils'

interface GrantCheckInsSectionProps {
  rewardsProgram: RewardsProgram
  checkInPolicy: FullCheckInPolicy
}

export const GrantCheckInsSection = ({
  rewardsProgram,
  checkInPolicy,
}: GrantCheckInsSectionProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  const toggleModalVisibility = () => {
    setIsModalVisible(!isModalVisible)
  }

  const daysToDisplay = addMissingDays(
    checkInPolicy?.checkInPointBonusSchedules ?? []
  )

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: { xs: 'flex-start', sm: 'center' },
          pr: { xs: '0px', sm: '16px' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            gap: '16px',
            alignItems: { xs: 'flex-start', sm: 'center' },
          }}
        >
          <Typography
            variant="h3"
            fontWeight="bold"
            sx={{ whiteSpace: 'nowrap' }}
          >
            Grant Check-Ins
          </Typography>
        </Box>
        <Button
          variant="text"
          onClick={toggleModalVisibility}
          sx={{
            maxWidth: { xs: '32px', sm: 'auto' },
            minWidth: 'auto',
            maxHeight: { xs: '20px', sm: 'auto' },
          }}
        >
          <Typography
            color={colorPrimitives.redGaming}
            variant="body-1"
            fontWeight="bold"
          >
            Edit
          </Typography>
        </Button>
      </Box>
      <GrantCheckInsDetails checkInPolicy={checkInPolicy} />
      <SettingsModal
        rewardsProgram={rewardsProgram}
        checkInPolicy={checkInPolicy}
        isModalVisible={isModalVisible}
        toggleModalVisibility={toggleModalVisibility}
        daysToDisplay={daysToDisplay ?? []}
      />
    </Box>
  )
}
