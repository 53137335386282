import {
  Controller,
  FormProvider,
  type Resolver,
  useForm,
} from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { PageHeader } from 'components/Shared/PageHeader'
import { type components } from 'api/playerPayback/api'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { Button, InputLabel, Stack, Typography } from '@mui/material'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { useSnackbar } from 'stores/useSnackbar'
import { useNavigate } from 'react-router-dom'
import { TextField } from 'components/Shared/TextField'
import { ApiError } from 'openapi-typescript-fetch'
import { FormFieldErrorMessage } from 'components/FormFieldErrorMessage'
import { isPresent } from '@jjvgaming/player-payback-library'
import { useCallback, useEffect, useState } from 'react'
import { useJJConnectUserSchema } from './useJJConnectUserSchema'
import { usePostJJConnectUser } from 'hooks/api/usePostJJConnectUser'
import { defaultValues, type JJConnectFormFields } from './JJConnectFormFields'
import { Page } from 'components/Shared/Page'
import { capitalize } from 'lodash'

export const NewLEConnectUserPage = () => {
  const setMessage = useSnackbar((state) => state.setMessage)
  const navigate = useNavigate()

  const { schema } = useJJConnectUserSchema()
  const formMethods = useForm<JJConnectFormFields>({
    defaultValues,
    resolver: yupResolver(schema) as Resolver<JJConnectFormFields>,
    mode: 'all',
    criteriaMode: 'all',
  })

  const {
    formState: { errors },
    handleSubmit,
    reset,
  } = formMethods

  const getCorporateAccounts = useTypedApiClient({
    path: '/admin/corporate-accounts',
    method: 'get',
  })
  const getLEUserEmail = useTypedApiClient({
    path: '/admin/licensed-establishments/users/emailmatch',
    method: 'get',
  })

  const queryClient = useQueryClient()

  const [emailData, setEmailData] = useState<JJConnectFormFields>()

  useEffect(() => {
    reset(emailData)
  }, [emailData])

  const emailExists = useCallback(
    async (email: string) => {
      if (!email) {
        setEmailData(undefined)
        return true
      }
      try {
        const patronEmailExistsQuery = await queryClient.fetchQuery({
          queryKey: ['/admin/licensed-establishments/users/emailmatch', email],
          queryFn: async () => {
            return await getLEUserEmail({
              email,
            })
          },
          staleTime: 60 * 1000,
        })
        setEmailData(patronEmailExistsQuery.data)
        return patronEmailExistsQuery
      } catch (error: any) {
        if (error.status !== 401) {
          setMessage(error.data, 'error')
        }
        setEmailData(undefined)
        return error
      }
    },
    [getLEUserEmail, queryClient]
  )

  const allCorporateAccountsQuery = useQuery({
    queryKey: ['/admin/corporate-accounts/list'],
    queryFn: async (): Promise<
      Array<components['schemas']['FullCorporateAccountDTO']> | null | undefined
    > => {
      const { data } = await getCorporateAccounts({})
      return data
    },
  })

  const postLicensedEstablishmentUserMutation = usePostJJConnectUser({
    onSuccess: (data) => {
      setMessage('J&J Connect User successfully created', 'success', {
        vertical: 'top',
        horizontal: 'right',
      })
      navigate(`/ClientManagement/LEConnectUsers/${data.id}`)
    },
    onError: (e) => {
      if (e instanceof ApiError) {
        setMessage(e.data, 'error', {
          vertical: 'top',
          horizontal: 'right',
        })
      } else {
        setMessage("Couldn't create J&J Connect User", 'error', {
          vertical: 'top',
          horizontal: 'right',
        })
      }
    },
  })

  const handleSubmitWrapper = handleSubmit(
    async (data: JJConnectFormFields) => {
      postLicensedEstablishmentUserMutation.mutate(data)
    }
  )

  if (allCorporateAccountsQuery.isPending) {
    return <ActivityIndicator />
  }

  if (allCorporateAccountsQuery.isError) {
    return (
      <Page
        header={
          <PageHeader
            title="J&J Connect Users"
            eyebrow="Client Management"
            isSecondary={true}
            backPath="/ClientManagement/LEConnectUsers"
            backText="J&J Connect Users"
          />
        }
      >
        <Typography>An error occurred</Typography>
      </Page>
    )
  }

  return (
    <Page
      header={
        <PageHeader
          title="Create a New J&J Connect User"
          subtitle={
            'You must fill out every field in the form before clicking the submit button.\nIncomplete submissions cannot be processed.'
          }
          isSecondary={true}
          backPath="/ClientManagement/LEConnectUsers"
          backText="J&J Connect Users"
        ></PageHeader>
      }
    >
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmitWrapper}>
          <Stack gap={2} maxWidth={500}>
            <InputLabel required htmlFor="LEUser.firstName" sx={{ mb: 0 }}>
              Contact Information
            </InputLabel>

            <Controller
              name="email"
              render={({ field: { onBlur, onChange, value } }) => (
                <TextField
                  title="Email"
                  placeholder="Email Address"
                  type="email"
                  fullWidth={true}
                  error={isPresent(errors?.email)}
                  autoCorrect="false"
                  spellCheck={false}
                  autoComplete="true"
                  value={value}
                  onBlur={onBlur}
                  onChange={async (e) => {
                    onChange(e)
                    await emailExists(e.target.value)
                  }}
                />
              )}
            />
            {errors.email?.message && (
              <FormFieldErrorMessage message={errors.email.message} />
            )}
            <Stack
              gap={2}
              direction={{ xs: 'column', sm: 'row' }}
              width={'100%'}
            >
              <Stack gap={1} width={'100%'}>
                <Controller
                  name="firstName"
                  render={({ field: { onBlur, onChange, value } }) => (
                    <TextField
                      name="firstName"
                      placeholder="First Name"
                      type="text"
                      onBlur={onBlur}
                      error={isPresent(errors.firstName)}
                      onChange={(e) => {
                        onChange(e)
                      }}
                      disabled={emailData !== undefined}
                      value={emailData?.firstName ?? value}
                    />
                  )}
                />
                {isPresent(errors.firstName) &&
                  isPresent(errors.firstName.message) && (
                    <FormFieldErrorMessage
                      message={capitalize(errors.firstName.message)}
                    />
                  )}
              </Stack>
              <Stack gap={1} width={'100%'}>
                <Controller
                  name="lastName"
                  render={({ field: { onBlur, onChange, value } }) => (
                    <TextField
                      name="Last Name"
                      placeholder="Last Name"
                      type="text"
                      onBlur={onBlur}
                      error={isPresent(errors.lastName)}
                      onChange={(e) => {
                        onChange(e)
                      }}
                      disabled={emailData !== undefined}
                      value={emailData?.lastName ?? value}
                    />
                  )}
                />
                {isPresent(errors.lastName) &&
                  isPresent(errors.lastName.message) && (
                    <FormFieldErrorMessage
                      message={capitalize(errors.lastName.message)}
                    />
                  )}
              </Stack>
            </Stack>
            <Stack direction="row" gap={2} mt={2}>
              <Button
                variant="outlined"
                onClick={() => navigate(`/ClientManagement/LEConnectUsers`)}
              >
                Cancel
              </Button>
              <Button variant="contained" type="submit">
                Submit
              </Button>
            </Stack>
          </Stack>
        </form>
      </FormProvider>
    </Page>
  )
}
