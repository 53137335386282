import { Page } from 'components/Shared/Page'
import { RewardProgramDetailHeader } from '../RewardsProgramDetailsHeader/RewardProgramDetailHeader'
import { useParams } from 'react-router-dom'
import { useGetRewardProgramById } from 'hooks/api/useGetRewardProgramById'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { useGetCheckInPolicyByRewardsProgramsId } from 'hooks/api/RewardProgram/useGetCheckInPolicyByRewardsProgramsId'
import { GrantCheckInsSection } from './GrantCheckInsSection'
import { RewardProgramSettingsSection } from './RewardProgramSettingsSection'
import { Stack } from '@mui/material'

export const RewardProgramSettingsPage = () => {
  const { id: rewardProgramId } = useParams()
  const rewardProgramQuery = useGetRewardProgramById({
    rewardProgramId: Number(rewardProgramId),
  })

  const policiesQuery = useGetCheckInPolicyByRewardsProgramsId({
    rewardProgramId: Number(rewardProgramId),
  })

  if (rewardProgramQuery.isPending || policiesQuery.isPending) {
    return <ActivityIndicator size="fullPage" />
  }

  if (rewardProgramQuery.isError || policiesQuery.isError) {
    return <p>An error occurred.</p>
  }

  return (
    <Page
      header={
        <RewardProgramDetailHeader
          rewardProgram={rewardProgramQuery.data}
          currentTab={`/Rewards/RewardPrograms/${rewardProgramId}/Settings`}
        />
      }
    >
      <Stack gap={2} width={'100%'}>
        <Stack>
          <GrantCheckInsSection
            rewardsProgram={rewardProgramQuery.data}
            checkInPolicy={policiesQuery.data}
          />
        </Stack>
        <Stack>
          <RewardProgramSettingsSection
            rewardsProgram={rewardProgramQuery.data}
          />
        </Stack>
      </Stack>
    </Page>
  )
}
