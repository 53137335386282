import type { SettingEULA } from 'types/api'
import { useQueryClient, useMutation } from '@tanstack/react-query'
import { useApiClient } from 'hooks/useApiClient'
import { type FileRequest } from 'types/fileUpload'

interface usePostSettingEULAProps {
  onSuccess?: (data: SettingEULA, variables: FileRequest) => void
  onError?: (error: Error, variables: FileRequest) => void
}

export const usePostSettingEULA = ({
  onSuccess,
  onError,
}: usePostSettingEULAProps) => {
  const postAPIClient = useApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (settingEULA: FileRequest) => {
      const body = new FormData()
      body.set('FileUpload', settingEULA.File)
      body.set('FileUploadName', settingEULA.FileName)
      body.set('FileUploadType', settingEULA.FileType)

      const { data, error } = await postAPIClient.post('/setting/eula', {
        params: {},
        body: {},
        bodySerializer: () => body,
      })
      return { data, error }
    },
    onSuccess: async ({ data, error }, variables: FileRequest) => {
      await queryClient.invalidateQueries({
        queryKey: ['/setting/eula'],
      })
      if (error !== undefined) {
        onError?.(error as Error, variables)
      } else {
        onSuccess?.(data as SettingEULA, variables)
      }
    },
    onError,
  })
}
