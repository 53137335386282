import { Box } from '@mui/material'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { Page } from 'components/Shared/Page'
import { isNil } from 'lodash'
import { useParams } from 'react-router-dom'
import { ErrorIndicator } from 'components/ErrorIndicator/ErrorIndicator'
import { OrganizationDetailsHeader } from './OrganizationDetailsHeader'
import { useGetOrganizationById } from 'hooks/api/useGetOrganizationById'
import { OrganizationDetailsSection } from './OrganizationDetails/OrganizationDetailsSection'
import { useState } from 'react'
import { AssociatedCorporateAccountDetailsSection } from './OrganizationAssociatedCorporateAccount/AssociatedCorporateAccountDetailsSection'
import { AssociatedLicensedEstablishmentsTableContainer } from './OrganizationAssociatedLicensedEstablishment/AssociatedLicensedEstablishmentsSection'
import { EditOrganizationDetailsModal } from './OrganizationDetails/EditOrganizationDetailsModal'
import { EditAssociatedCorporateAccountModal } from './OrganizationAssociatedCorporateAccount/EditAssociatedCorporateAccountModal'
import { EditAssociatedLicensedEstablishmentsModal } from './OrganizationAssociatedLicensedEstablishment/EditAssociatedLicensedEstablishmentsModal'
import { RewardProgramSection } from './OrganizationDetails/RewardsProgram'

export const OrganizationsDetailPage = () => {
  const params = useParams()
  const organizationId = Number(params.id)

  const [organizationDetailModalOpen, setOrganizationDetailModalOpen] =
    useState(false)

  const [
    associatedCorporateAccountModalOpen,
    setAssociatedCorporateAccountModalOpen,
  ] = useState(false)

  const [
    associatedLicensedEstablishmentsModalOpen,
    setAssociatedLicensedEstablishmentsModalOpen,
  ] = useState(false)

  const {
    isPending: isPendingOrganization,
    isError: isErrorOrganization,
    data: organization,
  } = useGetOrganizationById({ organizationId })

  if (isPendingOrganization) {
    return <ActivityIndicator />
  }

  if (isErrorOrganization || isNil(organization)) {
    return <ErrorIndicator />
  }

  if (!organization.id) {
    return <Box>Organization Id must not be null</Box>
  }

  const toggleOrganizationDetailModalOpen = async () =>
    setOrganizationDetailModalOpen(!organizationDetailModalOpen)

  const toggleAssociatedCorporateAccountModalOpen = async () =>
    setAssociatedCorporateAccountModalOpen(!associatedCorporateAccountModalOpen)

  const toggleAssociatedLicensedEstablishmentsModalOpen = async () =>
    setAssociatedLicensedEstablishmentsModalOpen(
      !associatedLicensedEstablishmentsModalOpen
    )

  return (
    <Page
      header={
        <OrganizationDetailsHeader
          organization={organization}
          currentTab={`/Organization/${Number(organization.id)}`}
        />
      }
    >
      <OrganizationDetailsSection
        organization={organization}
        onClickEdit={toggleOrganizationDetailModalOpen}
      />

      <Box mt={{ xs: 5, md: 8 }}>
        <AssociatedCorporateAccountDetailsSection
          corporateAccount={organization?.corporateAccount}
          onClickEdit={toggleAssociatedCorporateAccountModalOpen}
        />
      </Box>

      <Box mt={{ xs: 5, md: 8 }}>
        <AssociatedLicensedEstablishmentsTableContainer
          organizationId={organization?.id ?? 0}
          onClickEdit={toggleAssociatedLicensedEstablishmentsModalOpen}
        />
      </Box>

      <EditOrganizationDetailsModal
        organization={organization}
        isModalOpen={organizationDetailModalOpen}
        toggleModalIsOpen={toggleOrganizationDetailModalOpen}
      />

      <EditAssociatedCorporateAccountModal
        organization={organization}
        isModalOpen={associatedCorporateAccountModalOpen}
        toggleModalIsOpen={toggleAssociatedCorporateAccountModalOpen}
      />

      <EditAssociatedLicensedEstablishmentsModal
        organization={organization}
        isModalOpen={associatedLicensedEstablishmentsModalOpen}
        toggleModalIsOpen={toggleAssociatedLicensedEstablishmentsModalOpen}
      />

      <RewardProgramSection organization={organization} />
    </Page>
  )
}
