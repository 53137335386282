import { Box } from '@mui/material'
import type { GridColDef, GridEventListener } from '@mui/x-data-grid'
import { DataTable } from 'components/Shared/DataTable'
import { useLayout } from 'hooks/useLayout'
import { useNavigate } from 'react-router-dom'
import { type LicensedEstablishmentUser } from 'types/api'
import { DeleteLEConnectUserRenderCell } from './DeleteLEConnectUserRenderCell'
import { DeleteLEConnectUserModal } from './DeleteLEConnectUserModal'
import { isNil } from 'lodash'
import { useState } from 'react'

interface LEConnectUserListProps {
  users: LicensedEstablishmentUser[]
}

export const LEConnectUserList = ({ users }: LEConnectUserListProps) => {
  const [leUserRowData, setLEUserRowData] =
    useState<LicensedEstablishmentUser | null>()

  const defaultColumns = (isMobile: boolean): GridColDef[] => {
    return [
      {
        field: 'firstName',
        minWidth: 300,
        headerName: 'First Name',
        flex: 1,
        valueGetter: (params) => {
          return isMobile
            ? `${String(params.row.firstName)} ${String(params.row.lastName)}`
            : params.row.firstName
        },
      },
      {
        field: 'lastName',
        minWidth: 50,
        headerName: 'Last Name',
        flex: 1,
        valueGetter: (params) => {
          return isMobile ? '' : params.row.lastName
        },
      },
      {
        field: 'email',
        minWidth: 50,
        headerName: 'Email',
        flex: 1,
      },
      {
        field: '__menu__',
        headerName: '',
        align: 'left',
        renderCell: (params) => {
          return (
            <DeleteLEConnectUserRenderCell
              params={params}
              setLEUserRowData={setLEUserRowData}
            />
          )
        },
      },
    ]
  }
  const { isMobile } = useLayout()
  const navigate = useNavigate()

  const selectCell: GridEventListener<'cellClick'> = (params) => {
    if (params.field !== '__menu__' && params.id !== null) {
      navigate(`/ClientManagement/LEConnectUsers/${params.id}`)
    }
  }

  return (
    <Box paddingTop={{ xs: '20px', sm: '32px' }}>
      <DataTable
        columns={defaultColumns(isMobile)}
        rows={users}
        noDataMessage="No J&J Connect Users Found"
        sortFieldName="firstName"
        onCellClick={selectCell}
        getRowClassName={() => 'text-[#2c2c27]'}
      />
      <DeleteLEConnectUserModal
        data={leUserRowData}
        isModalOpen={!isNil(leUserRowData)}
        toggleIsOpen={() => setLEUserRowData(undefined)}
      />
    </Box>
  )
}
