import { Tab, Tabs } from '@mui/material'
import { Link } from 'react-router-dom'
import { useLayout } from 'hooks/useLayout'

export const OrganizationDetailsTabs = ({
  organizationId,
  currentTab,
}: {
  organizationId: number
  currentTab: string
}) => {
  const { isMobile } = useLayout()
  return (
    <Tabs value={currentTab} variant={isMobile ? 'fullWidth' : 'standard'}>
      <Tab
        label="Overview"
        value={`/Organization/${organizationId}`}
        to={`/Organization/${organizationId}`}
        component={Link}
      />
      <Tab
        label="Specials & Events"
        value={`/Organization/${organizationId}/OrganizationSpecialsEvents`}
        to={`/Organization/${organizationId}/OrganizationSpecialsEvents`}
        component={Link}
      />
    </Tabs>
  )
}
