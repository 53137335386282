import { type DefaultValues } from 'react-hook-form'
import { type jjConnectUserRoleType } from 'utils/util'

export interface JJConnectFormFields {
  id?: number
  email: string
  firstName: string
  lastName: string
  type: jjConnectUserRoleType
  corporateAccountId?: number
}

export const defaultValues: DefaultValues<JJConnectFormFields> = {
  id: undefined,
  email: '',
  firstName: '',
  lastName: '',
  type: 'Owner',
  corporateAccountId: undefined,
}
