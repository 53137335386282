import { type UseQueryResult, useQuery } from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { type components } from 'src/api/playerPayback/api'

export const useGetPagedPatronBalanceSummaryByRewardsProgramId = ({
  rewardProgramId,
  paginationModel,
  sortColumnName,
  sortOrder,
  enabled = true,
}: {
  rewardProgramId: number
  paginationModel: any
  sortColumnName: string
  sortOrder: string
  enabled?: boolean
}): UseQueryResult<
  components['schemas']['FullPointsLedgerSummaryDTOPageResultWithSumDTO']
> => {
  const get = useTypedApiClient({
    path: '/admin/rewards-programs/{id}/balances',
    method: 'get',
  })
  return useQuery({
    queryKey: [
      '/admin/rewards-programs/{id}/balances',
      rewardProgramId,
      paginationModel,
      sortColumnName,
      sortOrder,
    ],
    queryFn: async () => {
      const { data } = await get({
        id: rewardProgramId,
        CurrentPage: Number(paginationModel.page) + 1,
        PageSize: paginationModel.pageSize,
        sortColumnName,
        sortOrder,
      })
      return data
    },
    enabled,
  })
}
