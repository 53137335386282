import { isPresent } from '@jjvgaming/player-payback-library'
import { format, parse, parseISO } from 'date-fns'
import { isEmpty, isNil } from 'lodash'
import type { Address } from 'src/types/address'
import { type PatronAddress } from 'types/api'

export const camelCaseToTitleCase = (text: string): string => {
  const result = text.replace(/([A-Z])/g, ' $1')
  return result.charAt(0).toUpperCase() + result.slice(1)
}

export type CampaignMessageStatus = 'Scheduled' | 'Sent'

export type PromotionStatus = 'scheduled' | 'active' | 'expired'
export const calculatePromotionStatus = (
  startDate: Date | null,
  endDate: Date | null
): PromotionStatus => {
  const now = new Date()
  if (startDate && endDate) {
    if (now >= startDate && now <= endDate) {
      return 'active'
    } else if (now > endDate) {
      return 'expired'
    } else if (now < startDate) {
      return 'scheduled'
    }
  }
  return 'active'
}

export type PromotionType =
  | 'Global'
  | 'Location'
  | 'CorporateAccount'
  | 'Organization'

export const daysOfWeek = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
]

export type jjConnectUserRoleType =
  | 'Owner'
  | 'Staff'
  | 'SalesAccountManager'
  | 'LimitedLiveAtLocation'
  | 'SalesServiceRep'

export const minimumSearchLength = 3

export const convertScheduleHourTo12HourFormat = (
  timeStamp: string
): string => {
  const date = parse(timeStamp.replace(/\.\d+$/, ''), 'HH:mm:ss', new Date())
  return format(date, 'h:mm a')
}

export const convertTimestampTo24HourFormat = (
  timeStamp: string | Date
): string => {
  if (typeof timeStamp === 'string') {
    return format(
      parse(timeStamp.replace(/\.\d+$/, ''), 'hh:mm a', new Date()),
      'HH:mm:ss'
    )
  } else {
    return format(timeStamp, 'HH:mm:ss')
  }
}

export const formatAddress = (
  addresses: Address[],
  isCompleteAddress = false
) => {
  if (isEmpty(addresses)) {
    return 'Unspecified Address'
  }
  const { address1, address2, city, state, postalCode } = addresses[0]
  const parts = []
  if (!isEmpty(address1)) {
    parts.push(address1)
  }
  if (!isEmpty(address2) && isCompleteAddress) {
    parts.push(address2)
  }
  if (!isEmpty(city)) {
    parts.push(city)
  }
  if (!isEmpty(state)) {
    parts.push(state)
  }
  if (!isEmpty(postalCode) && isCompleteAddress) {
    parts.push(postalCode)
  }
  return parts.join(', ')
}

export const dataTableAddressFormatter =
  (isCompleteAddress?: boolean, isMobile?: boolean) =>
  (params: { value: Address[] }) =>
    isMobile
      ? `Address: ${formatAddress(params.value ?? [], isCompleteAddress)}`
      : formatAddress(params.value ?? [], isCompleteAddress)

export const dataTableIdFormatter =
  (isMobile: boolean) => (params: { value: string }) => {
    return isMobile ? `ID: ${params.value}` : params.value
  }

export const dataTableLicenseNumberFormatter =
  (isMobile: boolean) => (params: { value: string }) => {
    return isMobile ? `License #: ${params.value}` : params.value
  }

export const dataTablePromotionDateFormatter =
  (isMobile: boolean, startOrEnd: string) => (params: { value: Date }) => {
    if (params.value) {
      const startDate = new Date(params.value)
      if (!isMobile) {
        return `${startDate.toLocaleDateString()} ${startDate.toLocaleTimeString(
          [],
          {
            hour: '2-digit',
            minute: '2-digit',
          }
        )}`
      }
      if (isMobile) {
        return `${startOrEnd} Date: ${startDate.toLocaleDateString()} ${startDate.toLocaleTimeString(
          [],
          {
            hour: '2-digit',
            minute: '2-digit',
          }
        )}`
      }
    } else {
      return isMobile ? `${startOrEnd} Date: None` : 'None'
    }
  }

export const formatPoints = (value: number) => {
  return `${value} Point${value === 1 ? '' : 's'}`
}

export const formatDateTime = (
  stringDate: string | null | undefined,
  formatString: string = 'MM-dd-yyyy hh:mm aa'
) => {
  if (isNil(stringDate)) return

  const date = new Date(stringDate)

  return format(date, formatString)
}

type stringDate = string | null | undefined
export const formatFullDateTime = (stringDate: stringDate) => {
  if (isNil(stringDate)) {
    return
  }

  const date = parseISO(stringDate)
  return `${format(date, 'MM-dd-yyyy')} ${format(date, 'p')}`
}

export const formatFullDate = (stringDate: stringDate) => {
  if (isNil(stringDate)) {
    return
  }

  const date = parseISO(stringDate)
  return `${format(date, 'MM/dd/yyyy')}`
}

export const formatLatitude = (latitude: number) => {
  if (latitude > 0) {
    return `${latitude}° N`
  }
  return `${latitude}° S`
}

export const formatLongitude = (longitude: number) => {
  if (longitude > 0) {
    return `${longitude}° E`
  }
  return `${longitude}° W`
}

export const removeUndefined = (obj: Record<string, any>) => {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, v]) => v !== undefined)
  )
}

export const getLastUpdated = (
  modifiedOn: stringDate,
  formatString?: string
) =>
  modifiedOn
    ? `Last Updated: ${formatDateTime(modifiedOn, formatString)}`
    : undefined

export const getMemberSince = (createdOn: stringDate, formatString?: string) =>
  createdOn
    ? `Member Since: ${formatDateTime(createdOn, formatString)}`
    : undefined

export const validateLatitude = (value: number | undefined): boolean => {
  if (value === undefined) {
    return true
  }

  return value >= -90 && value <= 90
}

export const validateLongitude = (value: number | undefined): boolean => {
  if (value === undefined) {
    return true
  }

  return value >= -180 && value <= 180
}

export const convertToArray = <T>(value: T | T[]): T[] => {
  if (value instanceof Array) {
    return value
  }

  return [value]
}

export const addOpacity = (color: string, opacity: number): string => {
  if (color.length > 7) color = color.substring(0, color.length - 2)
  const _opacity = Math.round(Math.min(Math.max(opacity, 0), 1) * 255)
  let opacityHex = _opacity.toString(16).toUpperCase()
  if (opacityHex.length === 1) opacityHex = '0' + opacityHex

  return color + opacityHex
}

export const formatLEUserType = (type: string | undefined): string => {
  if (isNil(type)) {
    return ''
  }
  return type.split(/(?=[A-Z])/).join(' ')
}

export const getFileSizeKb = (fileSize: number) => {
  return fileSize / 1000
}

export const getFileExtension = (fileName: string) => {
  return fileName.split('.').pop() ?? ''
}

export const shouldValidateEndTime = (
  startDate: any,
  startTime: any,
  endDate: any,
  endTime: any
) =>
  startDate instanceof Date &&
  isFinite(+startDate) &&
  endDate instanceof Date &&
  isFinite(+endDate) &&
  typeof startTime === 'string' &&
  isPresent(startTime) &&
  typeof endTime === 'string' &&
  isPresent(endTime)

export const shouldValidateStartTime = (
  startDate: unknown,
  startTime: unknown
) => {
  return (
    startDate instanceof Date &&
    isFinite(+startDate) &&
    typeof startTime === 'string' &&
    isPresent(startTime)
  )
}

export const transformSelectedLocations = (selectedLocations: string[]) => {
  const transformedData = {
    licensedEstablishmentIds: Array<number>(),
    selectedCorporateAccountIds: Array<number>(),
    organizationIds: Array<number>(),
  }

  selectedLocations.forEach((location) => {
    const [type, id] = location.split('-')

    switch (type) {
      case 'licensedEstablishment':
        transformedData.licensedEstablishmentIds.push(Number(id))
        break
      case 'organization':
        transformedData.organizationIds.push(Number(id))
        break
      case 'corporateAccount':
        transformedData.selectedCorporateAccountIds.push(Number(id))
        break
    }
  })

  return { data: transformedData }
}

export const validateEndTime = (
  startDate: Date,
  startTime: string,
  endDate: Date,
  endTime: string
) => {
  if (startDate.getTime() !== endDate.getTime()) return true
  const fullStartDate = new Date(
    `${startDate.getMonth() + 1}/
    ${startDate.getDate()}/${startDate.getFullYear()} ${startTime}`
  )
  const fullEndDate = new Date(
    `${endDate.getMonth() + 1}/
    ${endDate.getDate()}/${endDate.getFullYear()} ${endTime}`
  )
  return fullStartDate.getTime() < fullEndDate.getTime()
}

export const validateStartTime = (startDate: Date, startTime: string) => {
  const fullStartDate = new Date(
    `${startDate.getMonth() + 1}/
    ${startDate.getDate()}/${startDate.getFullYear()} ${startTime}`
  )
  const fullCurrentDate = new Date()
  return fullCurrentDate.getTime() < fullStartDate.getTime()
}

export const formatTypeName = (typeName: string) => {
  if (!typeName) {
    return ''
  } else {
    switch (typeName) {
      case 'JJStoreItem':
        return 'J&J Store'
      case 'MatchPlay':
        return 'Match Play'
      case 'LocationOffer':
        return 'Location Offer'
    }
  }
}

export const formatLETypeName = (typeName: string) => {
  if (!typeName) {
    return ''
  } else {
    switch (typeName) {
      case 'CorporateAccount':
        return 'Corporate Account'
      case 'LicensedEstablishment':
        return 'Licensed Establishment'
      default:
        return typeName
    }
  }
}

export const verifyAddress = (address: PatronAddress | undefined): boolean => {
  return !!(
    address?.address1 &&
    address.city &&
    address.postalCode &&
    address.state
  )
}

export const pluralize = (count: number, noun: string, suffix = 's') =>
  `${count} ${noun}${count !== 1 ? suffix : ''}`

export const getExcludedDate = (
  excludeDate: stringDate,
  formatString?: string
) =>
  excludeDate
    ? `Excluded: ${formatDateTime(excludeDate, formatString)}`
    : undefined

export const getDeactivatedDate = (
  deactivatedDate: stringDate,
  formatString?: string
) =>
  deactivatedDate
    ? `Deactivated: ${formatDateTime(deactivatedDate, formatString)}`
    : undefined
