import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { useMutation } from '@tanstack/react-query'
import type { PatronCheckInManual } from 'types/api'

interface usePostManualPatronCheckinProps {
  onSuccess?: (
    data: PatronCheckInManual,
    variables: PatronCheckInManual
  ) => Promise<void>
  onError?: (error: Error, variables: PatronCheckInManual) => void
}

export const usePostManualPatronCheckin = ({
  onSuccess,
  onError,
}: usePostManualPatronCheckinProps) => {
  const post = useTypedApiClient({
    path: '/admin/corporate-accounts/licensed-establishments/checkin/manual',
    method: 'post',
  })

  return useMutation({
    mutationFn: async (manualCheckIn: PatronCheckInManual) => {
      const { data } = await post(manualCheckIn)
      return data
    },
    onSuccess: async (data, variables) => {
      await onSuccess?.(data, variables)
    },
    onError,
  })
}
