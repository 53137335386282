import { Box } from '@mui/material'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { Page } from 'components/Shared/Page'
import { isNil } from 'lodash'
import { useParams } from 'react-router-dom'
import { ErrorIndicator } from 'components/ErrorIndicator/ErrorIndicator'
import { OrganizationDetailsHeader } from '../OrganizationDetailsHeader'
import { useGetOrganizationById } from 'hooks/api/useGetOrganizationById'
import { PromotionsPageSection } from 'pages/Promotions/PromotionsPageSection'

export const OrganizationSpecialsEventsPage = () => {
  const params = useParams()
  const organizationId = Number(params.id)

  const {
    isPending: isPendingOrganization,
    isError: isErrorOrganization,
    data: organization,
  } = useGetOrganizationById({ organizationId })

  if (isPendingOrganization) {
    return <ActivityIndicator />
  }

  if (isErrorOrganization || isNil(organization)) {
    return <ErrorIndicator />
  }

  if (!organization.id) {
    return <Box>Organization Id must not be null</Box>
  }

  return (
    <Page
      header={
        <OrganizationDetailsHeader
          organization={organization}
          currentTab={`/Organization/${Number(
            organization.id
          )}/OrganizationSpecialsEvents`}
        />
      }
    >
      <PromotionsPageSection type={'Organization'} id={organizationId} />
    </Page>
  )
}
