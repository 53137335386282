import { Tab, Tabs } from '@mui/material'
import { Link } from 'react-router-dom'

export const CorporateAccountDetailsPageTabs = ({
  corporateAccountId,
  currentTab,
}: {
  corporateAccountId: number
  currentTab: string
}) => {
  return (
    <Tabs value={currentTab}>
      <Tab
        label="Overview"
        value={`/CorporateAccounts/${corporateAccountId}/CorporateAccountOverview`}
        to={`/CorporateAccounts/${corporateAccountId}/CorporateAccountOverview`}
        component={Link}
      />
      <Tab
        label="Associated Accounts"
        value={`/CorporateAccounts/${corporateAccountId}/CorporateAccountAssociatedAccounts`}
        to={`/CorporateAccounts/${corporateAccountId}/CorporateAccountAssociatedAccounts`}
        component={Link}
        sx={{
          whiteSpace: 'nowrap',
        }}
      />
      <Tab
        label="J&J Connect Users"
        value={`/CorporateAccounts/${corporateAccountId}/CorporateAccountLEUsers`}
        to={`/CorporateAccounts/${corporateAccountId}/CorporateAccountLEUsers`}
        component={Link}
        sx={{
          whiteSpace: 'nowrap',
        }}
      />
      <Tab
        label="Specials & Events"
        value={`/CorporateAccounts/${corporateAccountId}/CorporateAccountSpecialsEvents`}
        to={`/CorporateAccounts/${corporateAccountId}/CorporateAccountSpecialsEvents`}
        component={Link}
        sx={{
          whiteSpace: 'nowrap',
        }}
      />
    </Tabs>
  )
}
