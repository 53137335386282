import { Box, Button, FormControl, InputLabel, Typography } from '@mui/material'
import { type InferType } from 'yup'
import {
  type CampaignSchema,
  type LicensedEstablishmentSchema,
} from './CampaignSchema'
import { useFormContext, Controller } from 'react-hook-form'
import { ChooseLocationsModal } from './ChooseLocationsModal'
import { useState, useEffect } from 'react'

export const LocationSelect = ({ ...otherProps }) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const { watch, control, setValue } =
    useFormContext<InferType<typeof CampaignSchema>>()
  const licensedEstablishments = watch('licensedEstablishments')

  useEffect(() => {
    setValue('licensedEstablishments', [])
  }, [])

  const saveLocations = (
    locations: Array<InferType<typeof LicensedEstablishmentSchema>>
  ) => {
    setValue('licensedEstablishments', locations)
  }

  return (
    <>
      <FormControl>
        <InputLabel
          id={`licenseEstablishments-label`}
          htmlFor="licensedEstablishments"
          required={true}
        >
          Licensed Establishments
        </InputLabel>
        <Box display="flex" alignItems="flex-end">
          <Button variant="contained" onClick={() => setModalOpen(true)}>
            Choose Locations
          </Button>
          <Typography style={{ marginLeft: '10px' }}>
            {`${licensedEstablishments?.length} location(s) selected`}
          </Typography>
        </Box>
        <Controller
          name="licensedEstablishments"
          {...otherProps}
          control={control}
          defaultValue={[]}
          render={() => (
            <ChooseLocationsModal
              isModalOpen={modalOpen}
              toggleIsOpen={() => setModalOpen(false)}
              saveLocations={saveLocations}
            />
          )}
        />
      </FormControl>
    </>
  )
}
