import { useMemo, useState } from 'react'
import { isNil, some } from 'lodash'
import { useParams } from 'react-router-dom'
import { Page } from 'components/Shared/Page'
import { Typography, Button, Box } from '@mui/material'
import { Modal } from 'components/Modal/Modal'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { useGetRewardProgramById } from 'hooks/api/useGetRewardProgramById'
import { AssignCatalogToRewardProgram } from './AssignCatalogs/AssignCatalogToRewardProgram'
import { TransferPointsModal } from './TransferPoints/TransferPointsModal'
import { RewardProgramDetailHeader } from './RewardsProgramDetailsHeader/RewardProgramDetailHeader'
import { Title } from 'components/Title/Title'
import { StatusChip } from 'components/StatusChip/StatusChip'
import type {
  RewardsProgramCatalog,
  RewardsCatalog,
  RewardsProgramCatalogTier,
} from 'types/api'
import {
  DataGrid,
  type GridColDef,
  type GridRenderCellParams,
} from '@mui/x-data-grid'
import { grey } from '@mui/material/colors'
import { useGetRewardProgramCatalogByRewardProgramId } from 'hooks/api/useGetRewardProgramCatalogByRewardProgramId'
import { EditTierBasedPerkModal } from './EditTierBasedPerkModal'
import { CellLink } from 'components/CellLink/CellLink'
import { colorPrimitives } from 'components/Theme'
import { EditTierColumnRenderCell } from './EditTierColumnRenderCell'
import { RewardProgramDetailEditModal } from './RewardProgramDetailEditModal'

export interface ColumnType {
  name: string
  earlyAccess: boolean
  discountedRewardItem: boolean
  rewardsProgramCatalogTiers?: RewardsProgramCatalogTier[]
  rewardsCatalog?: RewardsCatalog
  rewardProgramCatalogId: number
}

const NoRowsOverlay = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        color: 'text.secondary',
        p: '16px',
        justifyContent: 'center',
        backgroundColor: grey[100],
        height: '110px',
      }}
    >
      <Typography variant="body-1">No Reward Program Catalogs</Typography>
    </Box>
  )
}

export const RewardProgramDetailPage = () => {
  const [
    isAssignCatalogToRewardProgramModalOpen,
    setIsAssignCatalogToRewardProgramModalOpen,
  ] = useState<boolean>(false)
  const [isTransferPointsModalOpen, setIsTransferPointsModalOpen] =
    useState<boolean>(false)

  const [editTierPerkModalData, setEditTierPerkModalData] = useState<{
    rewardsProgramCatalogTiers?: RewardsProgramCatalogTier[]
    rewardsCatalog?: RewardsCatalog
    rewardProgramCatalogId: number
  } | null>()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const toggleModalIsOpen = async () => {
    setIsModalOpen(!isModalOpen)
  }

  const toggleAssignCatalogToRewardProgramModalOpen = () => {
    setIsAssignCatalogToRewardProgramModalOpen(
      !isAssignCatalogToRewardProgramModalOpen
    )
  }
  const toggleTransferPointsModalOpen = () => {
    setIsTransferPointsModalOpen(!isTransferPointsModalOpen)
  }
  const { id: rewardProgramId } = useParams()
  const rewardProgramQuery = useGetRewardProgramById({
    rewardProgramId: Number(rewardProgramId),
  })

  const rewardProgramCatalogsQuery =
    useGetRewardProgramCatalogByRewardProgramId({
      rewardProgramId: Number(rewardProgramId),
    })
  const tableData: ColumnType[] = useMemo(() => {
    return (
      rewardProgramCatalogsQuery?.data?.map(
        (catalog: RewardsProgramCatalog) => {
          return {
            name: catalog.rewardsCatalog?.name ?? '',
            earlyAccess: some(
              catalog.rewardsProgramCatalogTiers,
              (x) => x.earlyAccess
            ),
            discountedRewardItem: some(
              catalog.rewardsProgramCatalogTiers,
              (x) => x.discountedRewardItem
            ),
            rewardsCatalog: catalog.rewardsCatalog,
            rewardsProgramCatalogTiers:
              catalog.rewardsProgramCatalogTiers ?? [],
            rewardProgramCatalogId: Number(catalog.id),
          } satisfies ColumnType
        }
      ) ?? []
    )
  }, [rewardProgramCatalogsQuery.data])

  if (rewardProgramQuery.isPending || rewardProgramCatalogsQuery.isPending) {
    return <ActivityIndicator size="fullPage" />
  }
  if (
    rewardProgramQuery.isError ||
    rewardProgramCatalogsQuery.isError ||
    (!rewardProgramQuery.data?.corporateAccount &&
      !rewardProgramQuery.data?.licensedEstablishment &&
      !rewardProgramQuery.data?.organization &&
      rewardProgramQuery.data?.type !== 'Global')
  ) {
    return <p>An error occurred.</p>
  }

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Reward Catalog Name',
      flex: 1,
      minWidth: 160,
      renderCell: (params: GridRenderCellParams<ColumnType>) => (
        <CellLink
          text={params.row.name}
          to={`/Rewards/RewardCatalogs/${params.row.rewardsCatalog?.id}`}
        />
      ),
    },
    {
      field: 'earlyAccess',
      headerName: 'Early Access',
      flex: 1,
      renderCell: (params: GridRenderCellParams<ColumnType>) => (
        <StatusChip
          active={params.row.earlyAccess}
          sx={{
            borderRadius: '2px 2px 2px 2px',
            color: params.row.discountedRewardItem
              ? colorPrimitives.black2
              : colorPrimitives.black,
          }}
        />
      ),
    },
    {
      field: 'discountedRewardItem',
      headerName: 'Discounted Reward',
      flex: 1,
      renderCell: (params: GridRenderCellParams<ColumnType>) => (
        <StatusChip
          active={params.row.discountedRewardItem}
          sx={{
            borderRadius: '2px 2px 2px 2px',
            color: params.row.discountedRewardItem
              ? colorPrimitives.black2
              : colorPrimitives.black,
          }}
        />
      ),
    },
    {
      field: '__menu__',
      headerName: '',
      align: 'right',
      renderCell: (params: GridRenderCellParams<ColumnType>) => (
        <EditTierColumnRenderCell
          isGlobal={rewardProgramQuery.data?.type === 'Global'}
          params={params}
          setEditTierPerkModalData={setEditTierPerkModalData}
        />
      ),
    },
  ]

  const rewardProgram = rewardProgramQuery.data

  const getParent = (
    type:
      | 'Global'
      | 'CorporateAccount'
      | 'LicensedEstablishment'
      | 'Organization'
      | undefined
  ) => {
    if (type === 'Global') {
      return <></>
    }

    const getHeadingName = (status: any) => {
      switch (status) {
        case 'CorporateAccount':
          return 'Corporate'
        case 'LicensedEstablishment':
          return 'Licensed Establishment'
        case 'Organization':
          return 'Organization'
        default:
          return ''
      }
    }
    const heading = getHeadingName(rewardProgramQuery.data.type)

    const getParentName = (status: any) => {
      switch (status) {
        case 'CorporateAccount':
          return rewardProgram?.corporateAccount?.name
        case 'LicensedEstablishment':
          return rewardProgram?.licensedEstablishment?.standardName
            ? rewardProgram?.licensedEstablishment?.standardName
            : rewardProgram?.licensedEstablishment?.name
        case 'Organization':
          return rewardProgram?.organization?.name
        default:
          return ''
      }
    }

    const getParentId = (status: any) => {
      switch (status) {
        case 'CorporateAccount':
          return rewardProgram?.corporateAccount?.id
        case 'LicensedEstablishment':
          return rewardProgram?.licensedEstablishment?.id
        case 'Organization':
          return rewardProgram?.organization?.id
        default:
          return ''
      }
    }

    return (
      <>
        <Box className="flex flex-col w-full space-y-6 sm:space-y-0 sm:flex-row sm:justify-between sm:items-center sm:pb-4">
          <Typography variant="h3">{heading} Account</Typography>
          <Box mt={{ xs: '24px', sm: '0' }}>
            <Button
              variant={'text'}
              onClick={() => {
                setIsModalOpen(!isModalOpen)
              }}
            >
              Edit
            </Button>
          </Box>
        </Box>
        <div className="flex flex-col border rounded pt-2 px-4 min-h-[118px] mt-3 mb-8 space-y-4 sm:space-y-0">
          <div className="flex flex-col min-h-[118px] justify-center space-y-4">
            <div className="grid grid-cols-1 sm:grid-cols-2 space-y-4 sm:space-y-0">
              <div>
                <Typography variant="body-3">{heading} Account Name</Typography>
                <Typography variant="body-1">
                  {getParentName(rewardProgramQuery.data.type)}
                </Typography>
              </div>
              <div>
                <Typography variant="body-3">{heading} Account ID</Typography>
                <Typography variant="body-1">
                  #{getParentId(rewardProgramQuery.data.type)}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <Page
      header={
        <RewardProgramDetailHeader
          rewardProgram={rewardProgramQuery.data}
          currentTab={`/Rewards/RewardPrograms/${Number(
            rewardProgramQuery.data.id
          )}`}
        />
      }
    >
      {getParent(rewardProgramQuery.data.type)}

      <Title
        title="Linked Reward Catalogs"
        actionComponent={
          <Button
            variant="text"
            className="!p-0 !m-0 !justify-right !text-right h-0 w-50"
            onClick={toggleAssignCatalogToRewardProgramModalOpen}
          >
            Assign Reward Catalogs
          </Button>
        }
      />
      <DataGrid
        className="mt-1"
        autoHeight
        columns={columns}
        rows={tableData ?? []}
        getRowHeight={() => 'auto'}
        getRowId={(x) => x.rewardsCatalog?.id ?? Math.random()}
        columnHeaderHeight={56}
        slots={{
          noRowsOverlay: NoRowsOverlay,
        }}
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableDensitySelector
        disableRowSelectionOnClick
        hideFooter
        sx={{
          '& .MuiDataGrid-row:hover': {
            backgroundColor: 'white',
          },
        }}
      />
      {rewardProgramQuery.data.licensedEstablishmentId && (
        <div className="mt-4">
          <Button variant="text" onClick={toggleTransferPointsModalOpen}>
            Transfer Points
          </Button>
        </div>
      )}
      <EditTierBasedPerkModal
        key={'edit-tier-modal'}
        isOpen={!isNil(editTierPerkModalData)}
        onCancel={() => {
          setEditTierPerkModalData(undefined)
        }}
        rewardsCatalog={editTierPerkModalData?.rewardsCatalog}
        rewardsProgramCatalogTiers={
          editTierPerkModalData?.rewardsProgramCatalogTiers
        }
        rewardProgramId={Number(rewardProgramId)}
        rewardProgramCatalogId={editTierPerkModalData?.rewardProgramCatalogId}
      />
      <Modal
        isOpen={isAssignCatalogToRewardProgramModalOpen}
        toggleIsOpen={toggleAssignCatalogToRewardProgramModalOpen}
        sx={{
          height: '90vh',
          width: { xs: '90vw', sm: '40vw' },
        }}
      >
        <AssignCatalogToRewardProgram
          rewardProgramId={Number(rewardProgramId)}
          toggleIsOpen={toggleAssignCatalogToRewardProgramModalOpen}
        />
      </Modal>
      <TransferPointsModal
        licensedEstablishmentId={
          rewardProgramQuery.data.licensedEstablishmentId ?? -1
        }
        rewardProgramId={Number(rewardProgramId)}
        toggleIsOpen={toggleTransferPointsModalOpen}
        isOpen={isTransferPointsModalOpen}
      />
      <RewardProgramDetailEditModal
        rewardsProgram={rewardProgramQuery.data}
        isModalOpen={isModalOpen}
        toggleModalIsOpen={toggleModalIsOpen}
        toggleIsOpen={toggleModalIsOpen}
      />
    </Page>
  )
}
