import { useState } from 'react'
import { Box, CircularProgress, Divider, Button, Stack } from '@mui/material'
import { Page } from 'components/Shared/Page'
import { PageHeader } from 'components/Shared/PageHeader'
import { useParams, useNavigate } from 'react-router-dom'
import {
  formatLETypeName,
  dataTableIdFormatter,
  getLastUpdated,
  formatPoints,
} from 'utils/util'
import { useGetRewardsCatalogById } from 'hooks/api/useGetRewardsCatalogById'
import { isNil } from 'lodash'
import type {
  GridColDef,
  GridEventListener,
  GridRowParams,
} from '@mui/x-data-grid'
import type { components } from 'api/playerPayback/api'
import { DataTable } from 'components/Shared/DataTable'
import { useLayout } from 'hooks/useLayout'
import { SectionHeader } from 'components/SectionHeader'
import { useGetRewardProgramsByCatalogId } from 'hooks/api/useGetRewardProgramsByCatalogId'
import { type RewardsCatalogReward } from 'utils/rewardsCatalogRewards'
import { type RewardsProgram } from 'types/api'
import { RewardsCatalogDeleteModal } from './RewardsCatalogDeleteModal'
import { RewardCatalogDetailSection } from './RewardCatalogDetailSection'
import { useDeleteRewardsCatalogRewards } from 'hooks/api/useDeleteRewardsCatalogRewards'
import { useSnackbar } from 'stores/useSnackbar'

const rewardItemsColumns = (isMobile: boolean): GridColDef[] => {
  return [
    {
      field: 'name',
      headerName: 'Reward Items',
      valueGetter: (params) => params.row.reward.name,
      flex: 0.5,
      minWidth: 160,
    },
    {
      field: 'id',
      headerName: 'Item ID',
      valueGetter: (params: {
        row: { reward: components['schemas']['FullRewardDTO'] }
      }) => `${isMobile ? 'Item ID# ' : '#'}${params.row.reward.id}`,
      flex: 0.5,
      minWidth: 160,
    },
    {
      field: 'amount',
      headerName: 'Redemption Cost',
      valueFormatter: (params: { value: number }) => formatPoints(params.value),
      flex: 1,
      minWidth: 160,
    },
  ]
}

const rewardProgramsColumns = (isMobile: boolean): GridColDef[] => [
  {
    field: 'name',
    headerName: 'Program Name',
    flex: 2,
    renderCell: (params) => (
      <p className="overflow-hidden whitespace-nowrap text-ellipsis">
        {params.row.name}
      </p>
    ),
  },
  {
    field: 'type',
    headerName: 'Program Type',
    flex: 1,
    renderCell: (params) => (
      <p className="overflow-hidden whitespace-nowrap text-ellipsis">
        {formatLETypeName(params.row.type)}
      </p>
    ),
  },
  {
    field: 'corporateAccount',
    headerName: 'Corporate Account',
    flex: 1,
    renderCell: (params) => (
      <p className="overflow-hidden whitespace-nowrap text-ellipsis">
        {params.row.corporateAccount?.name}
      </p>
    ),
  },
  {
    field: 'id',
    headerName: 'Program ID',
    flex: 0.5,
    valueFormatter: dataTableIdFormatter(isMobile),
    renderCell: (params) => (
      <p className="overflow-hidden whitespace-nowrap text-ellipsis">
        {params.row.id}
      </p>
    ),
  },
]

export const RewardsCatalogsDetailPage = () => {
  const navigate = useNavigate()
  const param = useParams()
  const { isMobile } = useLayout()
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const setSnackbarMessage = useSnackbar((state) => state.setMessage)

  const rewardsCatalogByIdQuery = useGetRewardsCatalogById({
    rewardsCatalogId: Number(param.id),
  })

  const rewardsProgramByCatalogIdQuery = useGetRewardProgramsByCatalogId({
    rewardsCatalogId: Number(param.id),
  })

  const deleteRewardsCatalogRewardsMutation = useDeleteRewardsCatalogRewards({
    onSuccess: () => {
      setSnackbarMessage(`Reward catalog items have been removed.`)
    },
    onError: () => {
      setSnackbarMessage(`Error: Cannot remove reward catalog items`, 'error')
    },
  })

  const handleDelete = () => {
    deleteRewardsCatalogRewardsMutation.mutate({ id: rewardsCatalog.id })
  }

  if (
    rewardsCatalogByIdQuery.isPending ||
    rewardsProgramByCatalogIdQuery.isPending
  ) {
    return <CircularProgress />
  }

  if (rewardsCatalogByIdQuery.isError) {
    return <p>An error occured.</p>
  }

  const rewardsCatalog = rewardsCatalogByIdQuery.data
  const rewardsProgram = rewardsProgramByCatalogIdQuery.data

  if (isNil(rewardsCatalog?.id)) {
    return <p>An error occured.</p>
  }

  const selectCatalogRow: GridEventListener<'rowClick'> = (
    params: GridRowParams<RewardsCatalogReward>
  ) => {
    const id = params.row.rewardId ?? 0
    id && navigate(`/Rewards/RewardItems/${id}`)
  }

  const selectProgramRow: GridEventListener<'rowClick'> = (
    params: GridRowParams<RewardsProgram>
  ) => {
    const id = params.row.id ?? 0
    id && navigate(`/Rewards/RewardPrograms/${id}`)
  }

  const toggleDeleteModalIsOpen = () => setIsDeleteModalOpen(!isDeleteModalOpen)

  return (
    <Page
      header={
        <Box>
          <PageHeader
            title={rewardsCatalog?.name ?? 'Reward Catalogs'}
            backText="Reward Catalogs"
            backPath="/Rewards/RewardCatalogs"
            isSecondary={true}
            subtitle={getLastUpdated(rewardsCatalog?.modifiedOn)}
          />
          <Divider />
        </Box>
      }
    >
      <Stack gap={2} width={'100%'}>
        <Stack>
          <RewardCatalogDetailSection rewardsCatalog={rewardsCatalog} />
        </Stack>
        <Stack>
          <Box marginTop={4}>
            <SectionHeader
              title="Catalog Items"
              buttonText="Manage Reward Items"
              onClickButton={() => {
                navigate(
                  `/Rewards/RewardCatalogs/${rewardsCatalog?.id}/ManageCatalogRewards`
                )
              }}
              showSecondaryButton={
                rewardsCatalog.rewardsCatalogRewards?.length !== 0
              }
              secondaryButtonText="Remove All Items"
              onClickSecondaryButton={handleDelete}
            />
          </Box>
          <DataTable
            data-testid="items-within-catalog-table"
            columns={rewardItemsColumns(isMobile)}
            rows={rewardsCatalog.rewardsCatalogRewards ?? []}
            onRowClick={selectCatalogRow}
          />

          <Box marginTop={4}>
            <SectionHeader title="Linked Reward Programs" showButton={false} />
          </Box>
          <DataTable
            data-testid="items-within-Programs-table"
            columns={rewardProgramsColumns(isMobile)}
            rows={rewardsProgram ?? []}
            onRowClick={selectProgramRow}
          />
          <Box mt={2}>
            <Button
              variant={'text'}
              onClick={() => {
                setIsDeleteModalOpen(true)
              }}
            >
              Delete Catalog
            </Button>
          </Box>
          <RewardsCatalogDeleteModal
            toggleModalIsOpen={toggleDeleteModalIsOpen}
            isModalOpen={isDeleteModalOpen}
            rewardsCatalog={rewardsCatalog}
          />
        </Stack>
      </Stack>
    </Page>
  )
}
