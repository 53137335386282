import { Tab, Tabs } from '@mui/material'
import { Link } from 'react-router-dom'

export const SettingsDetailsPageTabs = ({
  currentTab,
}: {
  currentTab: any
}) => {
  return (
    <Tabs value={currentTab}>
      <Tab
        label="EULA"
        value={`/Settings/SettingsDetailsPageEULA`}
        to={`/Settings`}
        component={Link}
      />
    </Tabs>
  )
}
