import { useQuery } from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'

export const useGetSettingEULA = () => {
  const get = useTypedApiClient({
    path: '/setting/eula',
    method: 'get',
  })
  return useQuery({
    queryKey: ['/setting/eula'],
    queryFn: async () => {
      const { data } = await get({})
      return data
    },
  })
}
