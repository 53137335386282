import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLayout } from 'hooks/useLayout'
import { Modal } from 'components/Modal/Modal'
import { useSnackbar } from 'stores/useSnackbar'
import { type StateFileUpload } from 'types/fileUpload'
import { usePostSettingEULA } from 'hooks/api/Settings/usePostSettingEULA'
import { Box, Button, Typography } from '@mui/material'
import { ModalFullBody } from 'components/Modal/ModalFullBody'
import { ModalFullFooter } from 'components/Modal/ModalFullFooter'
import { ModalFullHeader } from 'components/Modal/ModalFullHeader'
import { FormFieldErrorMessage } from 'components/FormFieldErrorMessage'
import { UploaderDisplayer } from 'components/FileUploader/UploaderDisplayer'
import { ActivityButton } from 'components/ActivityButton'

export const UploadFile = ({
  isModalOpen,
  toggleModalIsOpen,
}: {
  isModalOpen: boolean
  toggleModalIsOpen: () => void
}) => {
  const setSnackbarMessage = useSnackbar((state) => state.setMessage)
  const [files, setFiles] = useState<File[]>([])
  const [showFiles, setShowFiles] = useState(false)
  const [stateFilesUpload, setStateFilesUpload] = useState<StateFileUpload>(0)
  const [messageError, setMessageError] = useState('')
  const { isMobile } = useLayout()

  const { handleSubmit } = useForm()

  const toggleShowFiles = () => setShowFiles(!showFiles)
  const toggleStateFilesUpload = (value: StateFileUpload) => {
    setMessageError('')
    if (stateFilesUpload === 0) {
      setStateFilesUpload(value)
    }
    if (value === 2) {
      setMessageError('Please correct errors to continue')
    }
  }

  const removeFile = (file: File) => {
    setFiles(
      files?.filter((fileMatch) => {
        return fileMatch.name !== file.name
      })
    )
    setStateFilesUpload(0)
    toggleShowFiles()
  }

  const addFiles = (files: File[]) => {
    setFiles(files)
    toggleShowFiles()
  }

  const resetAll = () => {
    toggleModalIsOpen()
    setShowFiles(false)
    setFiles([])
    setMessageError('')
  }

  const { mutate: putFileSettingMutation, isPending: isUploadActive } =
    usePostSettingEULA({
      onSuccess: () => {
        resetAll()
        setSnackbarMessage(`EULA has been updated`)
      },
      onError: (error, _) => {
        console.log(error)
        setMessageError(error.message)
      },
    })

  const updateRewardWrapper = handleSubmit(async () => {
    if (stateFilesUpload !== 1) {
      setMessageError('Please correct errors to continue')
      return
    }

    putFileSettingMutation({
      FileName: files[0].name,
      File: files[0],
      FileType: 'PDF',
    })
  })

  return (
    <Modal
      isOpen={isModalOpen}
      toggleIsOpen={() => {}}
      sx={{
        width: isMobile ? '100%' : '60%',
        height: isMobile ? '100%' : '75%',
      }}
    >
      <Box>
        <form onSubmit={updateRewardWrapper}>
          <ModalFullHeader toggleIsOpen={toggleModalIsOpen} title="EULA File" />
          <ModalFullBody heightModal={isMobile ? '100%' : '75%'}>
            {messageError && (
              <Box className="w-full mb-[3%]">
                <FormFieldErrorMessage
                  message={messageError}
                  variant="contained"
                />
              </Box>
            )}
            <Typography variant="subtitle-2" className="w-full">
              Upload EULA
            </Typography>
            <UploaderDisplayer
              files={files}
              addFiles={addFiles}
              removeFile={removeFile}
              toggleStateFilesUpload={toggleStateFilesUpload}
              showImages={showFiles}
              textToShowButton="Click to upload."
              informationFileUpload="Saving will overwrite any existing EULA."
              maxSizeAllowedUpload={500}
              fileFormatsAllowedUpload="application/pdf"
            />
          </ModalFullBody>
          <ModalFullFooter>
            <Button onClick={resetAll}>Cancel</Button>
            <ActivityButton
              type="submit"
              variant="contained"
              active={isUploadActive}
            >
              Save
            </ActivityButton>
          </ModalFullFooter>
        </form>
      </Box>
    </Modal>
  )
}
