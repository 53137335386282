import { isNil } from 'lodash'
import { useLayout } from 'hooks/useLayout'
import { useNavigate } from 'react-router-dom'
import { DataTablePaging } from 'components/Shared/DataTablePaging'
import {
  type GridRowParams,
  type GridColDef,
  type GridEventListener,
} from '@mui/x-data-grid'
import { type PagedPatron } from 'types/api'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { formatPhoneNumber } from 'utils/phoneUtils'

const MobileColumns: Array<GridColDef<PagedPatron>> = [
  {
    field: 'id',
    headerName: 'Patron ID',
    minWidth: 120,
    flex: 1,
    valueGetter: (params) => {
      return `#${params.row.id}`
    },
  },
  {
    field: 'fullName',
    headerName: 'Full name',
    minWidth: 130,
    flex: 1,
    valueGetter: (params) => {
      return `${params.row.firstName} ${params.row.lastName}`
    },
  },
  {
    field: 'email',
    headerName: 'Email',
    minWidth: 130,
    flex: 1,
    renderCell: (params) => (
      <div
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {params.value}
      </div>
    ),
  },
  {
    field: 'phoneNumber',
    headerName: 'Phone Number',
    minWidth: 170,
    flex: 1,
    valueGetter: (params) => {
      return formatPhoneNumber(params.row.phoneNumber)
    },
  },
]

const DesktopColumns: Array<GridColDef<PagedPatron>> = [
  {
    field: 'id',
    headerName: 'Patron ID',
    minWidth: 120,
    flex: 1,
    valueGetter: (params) => {
      return `#${params.row.id}`
    },
  },
  {
    field: 'firstName',
    headerName: 'First Name',
    minWidth: 130,
    flex: 1,
  },
  { field: 'lastName', headerName: 'Last Name', minWidth: 130, flex: 1 },
  {
    field: 'email',
    headerName: 'Email',
    minWidth: 130,
    flex: 1,
    renderCell: (params) => (
      <div
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {params.value}
      </div>
    ),
  },
  {
    field: 'phoneNumber',
    headerName: 'Phone Number',
    minWidth: 170,
    flex: 1,
    valueGetter: (params) => {
      return formatPhoneNumber(params.row.phoneNumber)
    },
  },
]

export const PatronsTable = ({
  isPending,
  isError,
  data,
  totalCount,
  paginationModel,
  onPaginationModelChange,
}: {
  isPending: boolean
  isError: boolean
  data: PagedPatron[]
  totalCount: number
  paginationModel: any
  onPaginationModelChange: any
}) => {
  const navigate = useNavigate()
  const { isMobile } = useLayout()

  const selectRow: GridEventListener<'rowClick'> = (params: GridRowParams) => {
    navigate(`/PatronAccounts/${params.id}/Overview`)
  }

  if (isPending || isNil(data)) {
    return <ActivityIndicator />
  }

  if (isError) {
    return <p>Error loading patrons</p>
  }

  return (
    <DataTablePaging
      columns={isMobile ? MobileColumns : DesktopColumns}
      rows={data}
      totalCount={totalCount}
      onRowClick={selectRow}
      getRowId={(row) => row.id ?? 0} // this should never happen, it's because our api defs indicate id is optional
      paginationModel={paginationModel}
      onPaginationModelChange={onPaginationModelChange}
    />
  )
}
