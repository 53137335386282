import { Page } from 'components/Shared/Page'
import { PatronsTable } from 'pages/Patrons/PatronsTable'
import { PageHeader } from 'components/Shared/PageHeader'
import { Stack } from '@mui/material'
import React, { useEffect } from 'react'
import { type PatronPageResult } from 'types/api'
import { type ApiError } from 'openapi-typescript-fetch'
import { useDebounce } from '@uidotdev/usehooks'
import { SearchField } from 'components/SearchField'
import { useQuery } from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { minimumSearchLength } from 'utils/util'
import { type GridPaginationModel } from '@mui/x-data-grid'

export const PatronAccountsPage = () => {
  const [query, setQuery] = React.useState('')
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 25,
    page: 0,
  })
  const debouncedQuery = useDebounce(query, 500)

  const getPatrons = useTypedApiClient({
    path: '/admin/patrons/{currentPage}/{pageSize}',
    method: 'get',
  })

  useEffect(() => {
    if (debouncedQuery.length >= minimumSearchLength) {
      // set back to page 0 when user searches to make sure results show
      setPaginationModel({ page: 0, pageSize: paginationModel.pageSize })
    }
  }, [debouncedQuery])

  const patronsQuery = useQuery<PatronPageResult, ApiError>({
    queryKey: ['patrons', debouncedQuery, paginationModel],
    queryFn: async () => {
      if (debouncedQuery.length >= minimumSearchLength) {
        return (
          await getPatrons({
            currentPage: paginationModel.page + 1,
            pageSize: paginationModel.pageSize,
            searchTerm: debouncedQuery,
          })
        ).data
      } else {
        return (
          await getPatrons({
            currentPage: paginationModel.page + 1,
            pageSize: paginationModel.pageSize,
          })
        ).data
      }
    },
  })

  const onPaginationModelChange = (model: GridPaginationModel) => {
    setPaginationModel(model)
  }

  return (
    <Page header={<PageHeader title="Patron Accounts"></PageHeader>}>
      <Stack
        direction={{ md: 'row', sm: 'column', xs: 'column' }}
        gap={{ md: 0, sm: 2, xs: 2 }}
        mb={{ md: 4, sm: 2, xs: 2 }}
        justifyContent={'space-between'}
      >
        <SearchField
          placeholder="Search Patrons"
          onChange={(e) => setQuery(e.target.value)}
          fullWidth
        />
      </Stack>

      <PatronsTable
        isError={patronsQuery.error !== null}
        isPending={
          patronsQuery.isFetching ||
          patronsQuery.isPending ||
          patronsQuery.isLoading
        }
        data={patronsQuery.data?.results ?? []}
        totalCount={patronsQuery.data?.rowCount ?? 0}
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
      />
    </Page>
  )
}
