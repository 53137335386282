import { isNil } from 'lodash'
import { formatPoints } from 'utils/util'
import { useLayout } from 'hooks/useLayout'
import { useParams } from 'react-router-dom'
import { Box } from '@mui/material'
import type { PointsLedgerSummary } from 'types/api'
import { useCallback, useState, useMemo } from 'react'
import { DataTable } from 'components/Shared/DataTable'
import { ManuallyGrantPointsModal } from './ManuallyGrantPointsModal'
import { useGetPatronById } from 'hooks/api/Patron/useGetPatronById'
import type {
  GridColDef,
  GridEventListener,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { PatronDetailsHeader } from '../DetailsHeader/PatronDetailsHeader'
import GiftIcon from 'assets/gift_empty_state.svg'
import { SectionHeader } from 'components/SectionHeader'
import { AddRewardProgramModal } from './AddRewardProgramModal'
import { green, grey } from '@mui/material/colors'

const globalRewardProgramColumns = (isMobile: boolean): GridColDef[] => {
  return [
    {
      field: 'name',
      headerName: 'Reward Program Name',
      minWidth: 120,
      flex: 1.5,
      valueGetter: (params: { row: PointsLedgerSummary }) => {
        return params.row.rewardsProgram?.name
      },
    },
    {
      field: 'id',
      headerName: 'ID',
      minWidth: 120,
      flex: 0.5,
      valueGetter: (params: { row: PointsLedgerSummary }) => {
        const id = params.row.rewardsProgram?.id
        return isMobile ? `ID: ${id}` : id
      },
    },
    {
      field: 'balance',
      headerName: 'Current Point Balance',
      minWidth: 120,
      flex: 2,
      valueGetter: (params: { row: PointsLedgerSummary }) => {
        const points = formatPoints(params.row.balance)
        return isMobile ? `Current Point Balance ${points}` : points
      },
    },
    {
      field: 'active',
      headerName: 'Status',
      maxWidth: 120,
      flex: 2,
      renderCell: (params: GridRenderCellParams<PointsLedgerSummary>) => {
        return (
          <Box
            className="px-2 pt-1 pb-0.5 rounded-sm font-bold uppercase min-w-full min-h-full flex justify-center  items-center"
            sx={{
              backgroundColor: params.row.rewardsProgram?.active
                ? green[100]
                : grey[300],
              color: params.row.rewardsProgram?.active ? green[900] : grey[700],
            }}
          >
            {params.row.rewardsProgram?.active ? 'ACTIVE' : 'INACTIVE'}
          </Box>
        )
      },
    },
  ]
}

export const PatronPointBalancePage = () => {
  const { id: patronAccountId } = useParams()
  const { isMobile } = useLayout()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isAddRewardProgramModalOpen, setIsAddRewardProgramModalOpen] =
    useState(false)
  const [selectedSummary, setSelectedSummary] = useState<PointsLedgerSummary>()

  const toggleModalIsOpen = () => {
    setIsModalOpen(!isModalOpen)
  }

  const patronQuery = useGetPatronById({
    id: Number(patronAccountId),
  })

  const defaultGlobalRewardProgram: PointsLedgerSummary = {
    id: -1,
    balance: 0,
    rewardsProgram: {
      id: 1,
      name: 'J&J Gaming',
      type: 'Global',
      active: true,
    },
    patronId: Number(patronAccountId),
    rewardsProgramId: 1,
  }

  const rewardProgramIds: Array<number | undefined> | undefined =
    patronQuery.data?.pointsLedgerSummaries?.map((pl) => pl?.rewardsProgram?.id)

  const getPointsLedger = useCallback(
    (includeGlobal: boolean = false, id: number = 0) => {
      const filtered =
        patronQuery.data?.pointsLedgerSummaries?.filter(
          (pointLedgerSummary) =>
            (includeGlobal
              ? pointLedgerSummary.rewardsProgram?.type === 'Global'
              : pointLedgerSummary.rewardsProgram?.type !== 'Global') &&
            (id === 0 || pointLedgerSummary.rewardsProgram?.id === id)
        ) ?? []

      if (includeGlobal && filtered.length === 0) {
        return [defaultGlobalRewardProgram]
      }

      return filtered
    },
    [patronQuery.data?.pointsLedgerSummaries]
  )

  const globalPointsLedgerRows = useMemo(
    () => getPointsLedger(true),
    [getPointsLedger]
  )

  const noGlobalPointsLedgerRows = useMemo(
    () => getPointsLedger(),
    [getPointsLedger]
  )

  if (patronQuery.isPending) {
    return <ActivityIndicator />
  }

  if (patronQuery.isError || isNil(patronQuery.data)) {
    return <p>An error occurred.</p>
  }

  const selectRow: GridEventListener<'rowClick'> = (
    params: GridRowParams<PointsLedgerSummary>
  ) => {
    if (params.row.rewardsProgram?.active) {
      setSelectedSummary(params.row)
      toggleModalIsOpen()
    }
  }

  return (
    <PatronDetailsHeader
      patron={patronQuery.data}
      currentTab={`/PatronAccounts/${patronAccountId}/PointBalance`}
    >
      <Box
        className="flex flex-col w-full sm:flex-row sm:justify-between sm:items-start"
        paddingTop={{ xs: 2, sm: 3 }}
      >
        <SectionHeader
          title="Global Reward Programs"
          buttonText="Award Points"
          onClickButton={() => {
            if (globalPointsLedgerRows.length > 0) {
              setSelectedSummary(globalPointsLedgerRows[0])
              toggleModalIsOpen()
            }
          }}
        />
      </Box>
      <DataTable
        columns={globalRewardProgramColumns(isMobile)}
        rows={globalPointsLedgerRows}
        onRowClick={selectRow}
      />

      <SectionHeader
        sx={{ paddingTop: { xs: 5, sm: 6 } }}
        title="Location Specific Reward Programs"
        buttonText="Add Reward Program"
        onClickButton={() => {
          setIsAddRewardProgramModalOpen(!isAddRewardProgramModalOpen)
        }}
      />
      <DataTable
        columns={globalRewardProgramColumns(isMobile)}
        rows={noGlobalPointsLedgerRows}
        onRowClick={selectRow}
        noDataMessage="No location points have been earned yet."
        emptyDataImageSource={GiftIcon}
      />

      <ManuallyGrantPointsModal
        patronId={Number(patronAccountId)}
        isModalOpen={isModalOpen}
        toggleModalIsOpen={toggleModalIsOpen}
        pointLedgerSummary={selectedSummary}
      />
      <AddRewardProgramModal
        patronId={Number(patronAccountId)}
        isModalOpen={isAddRewardProgramModalOpen}
        pointLedgerSummary={selectedSummary}
        toggleModalIsOpen={() => {
          setIsAddRewardProgramModalOpen(!isAddRewardProgramModalOpen)
        }}
        rewardProgramIds={rewardProgramIds}
      />
    </PatronDetailsHeader>
  )
}
