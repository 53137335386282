import { Box, Grid, Typography, useTheme } from '@mui/material'
import { SectionHeader } from 'components/SectionHeader'
import { type LicensedEstablishmentDetailsProps } from '../types'
import { formatPhoneNumber } from 'utils/phoneUtils'

export const BusinessDetailsDesktop = ({
  licensedEstablishment,
  onClickEdit,
}: LicensedEstablishmentDetailsProps) => {
  const theme = useTheme()
  return (
    <Box>
      <SectionHeader title="Business Details" onClickButton={onClickEdit} />

      <Box
        border={{ md: `1px solid ${theme.palette.grey[200]}` }}
        borderRadius={'5px'}
        p={{ xs: 0, sm: 3 }}
      >
        <Grid container columnGap={20}>
          <Grid item md={2}>
            <Typography variant="body2" pb={0.5} color="text.secondary">
              Legal Company Name
            </Typography>
            <Typography variant="body1" pb={2}>
              {licensedEstablishment.name ? licensedEstablishment.name : '-'}
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body2" pb={0.5} color="text.secondary">
              DBA Name
            </Typography>
            <Typography variant="body1" pb={2}>
              {licensedEstablishment.standardName
                ? licensedEstablishment.standardName
                : '-'}
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body2" pb={0.5} color="text.secondary">
              Short Name
            </Typography>
            <Typography variant="body1" pb={2}>
              {licensedEstablishment.shortName
                ? licensedEstablishment?.shortName
                : '-'}
            </Typography>
          </Grid>

          <Grid item md={2}>
            <Typography variant="body2" pb={0.5} color="text.secondary">
              Establishment Trade Type
            </Typography>
            <Typography variant="body1" pb={2}>
              {licensedEstablishment.type ? licensedEstablishment.type : '-'}
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body2" pb={0.5} color="text.secondary">
              License Number
            </Typography>
            <Typography variant="body1" pb={2}>
              {licensedEstablishment.licenseNumber
                ? `#${licensedEstablishment.licenseNumber}`
                : '-'}
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body2" pb={0.5} color="text.secondary">
              LE ID Number
            </Typography>
            <Typography variant="body1" pb={2}>
              {licensedEstablishment.id ? `#${licensedEstablishment.id}` : '-'}
            </Typography>
          </Grid>

          <Grid item md={2}>
            <Typography variant="body2" pb={0.5} color="text.secondary">
              Phone Number
            </Typography>
            <Typography variant="body1" pb={2}>
              {formatPhoneNumber(licensedEstablishment.phoneNumber)}
            </Typography>
          </Grid>

          <Grid item md={2}>
            <Typography variant="body2" pb={0.5} color="text.secondary">
              Gaming Status
            </Typography>
            <Typography variant="body1" pb={2}>
              {licensedEstablishment.gamingStatus}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
