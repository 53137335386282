import { object, string } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { ActivityButton } from 'components/ActivityButton'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material'
import { FormFieldErrorMessage } from 'components/FormFieldErrorMessage'
import { isPresent } from '@jjvgaming/player-payback-library'
import type { RewardsProgram } from 'types/api'
import { grey } from '@mui/material/colors'
import { useSnackbar } from 'stores/useSnackbar'
import { type ApiError } from 'openapi-typescript-fetch'
import { useQueryClient } from '@tanstack/react-query'
import { usePutRewardsProgram } from 'hooks/api/usePutRewardsProgram'

const RewardProgramDetailsEditSchema = object({
  programName: string().required('Required').max(100),
})

export const RewardProgramDetailEditModal = ({
  isModalOpen,
  toggleIsOpen,
  toggleModalIsOpen,
  rewardsProgram,
}: {
  isModalOpen: boolean
  toggleModalIsOpen: () => void
  toggleIsOpen: () => void
  rewardsProgram: RewardsProgram
}) => {
  const formMethods = useForm({
    resolver: yupResolver(RewardProgramDetailsEditSchema),
  })

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = formMethods

  const setSnackbarMessage = useSnackbar((state) => state.setMessage)
  const queryClient = useQueryClient()
  const putRewardProgramMutation = usePutRewardsProgram({
    onSuccess: () => {
      setSnackbarMessage(`Reward Program has been updated.`)
      toggleModalIsOpen()
      void queryClient.invalidateQueries({
        queryKey: ['/admin/rewards-programs'],
      })
    },
    onError: (error: ApiError) => {
      if (typeof error.data === 'string') {
        if (error.data.includes('ProgramName')) {
          control.setError('programName', { message: error.data })
        }
      } else {
        setSnackbarMessage(
          `Error: Cannot save Reward Program information`,
          'error'
        )
      }
    },
  })

  const handleSubmitWrapper = handleSubmit((formData) => {
    putRewardProgramMutation.mutate({
      id: rewardsProgram.id,
      rewardsProgramData: {
        name: formData.programName,
        description: rewardsProgram.description,
        active: rewardsProgram.active,
        type: rewardsProgram.type,
        maxAllowedDailyPoints: rewardsProgram.maxAllowedDailyPoints,
        maxRedemptionsPerPatronPerDay:
          rewardsProgram.maxRedemptionsPerPatronPerDay,
        corporateAccountId: rewardsProgram.corporateAccountId,
        licensedEstablishmentId: rewardsProgram.licensedEstablishmentId,
        organizationId: rewardsProgram.organizationId,
      },
    })
  })

  return (
    <Dialog
      open={isModalOpen}
      maxWidth={'xl'}
      fullWidth
      PaperProps={{ sx: { p: 0, maxWidth: '900px' } }}
      onClose={toggleIsOpen}
    >
      <form onSubmit={handleSubmitWrapper} noValidate>
        <DialogTitle bgcolor={grey[50]} sx={{ p: 0 }} className="border-b">
          <Box px={4} py={4}>
            <Typography variant="h3" fontWeight={'bold'}>
              Program Details
            </Typography>
            <Typography variant="body-1" color={'text.secondary'}>
              {rewardsProgram?.name ?? ''}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ pt: 4, px: 4 }}>
          <Grid container pt={4}>
            <Grid item xs={12} md={6} pt={{ md: 2 }}>
              <InputLabel
                required
                htmlFor="RewardsProgram.Name"
                sx={{ fontSize: '16px', fontWeight: '100' }}
              >
                Reward Program Name
              </InputLabel>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                {...register('programName', { required: true })}
                defaultValue={rewardsProgram?.name ?? ''}
                error={isPresent(errors.programName)}
              />
              {errors.programName?.message && (
                <FormFieldErrorMessage message={errors.programName?.message} />
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{ borderTop: `1px solid ${grey[300]}`, pt: 2, pb: 2, px: 4 }}
        >
          <Button onClick={toggleModalIsOpen}>Cancel</Button>
          <ActivityButton
            variant="contained"
            active={false}
            onClick={handleSubmitWrapper}
          >
            Save
          </ActivityButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}
