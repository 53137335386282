import { Box, Typography } from '@mui/material'
import type { components } from 'api/playerPayback/api'
import { PageHeader } from 'components/Shared/PageHeader'
import { isNil } from 'lodash'
import { Colors } from 'components/Theme'

export const CommunicationDetailsHeader = ({
  message,
}: {
  message: components['schemas']['CampaignDto']
}) => {
  if (!message.id) {
    return <Typography>Promotion Id must not be null.</Typography>
  }

  const status = message.status?.toUpperCase()

  const campaignMessageBackgroundColor: Record<string, string> = {
    SCHEDULED: Colors.campaignMessageStatus.background.Scheduled,
    SENT: Colors.campaignMessageStatus.background.Sent,
  }

  const campaignMessageTextColor: Record<string, string> = {
    SCHEDULED: Colors.campaignMessageStatus.text.Scheduled,
    SENT: Colors.campaignMessageStatus.text.Sent,
  }

  return (
    <Box>
      <PageHeader
        title={isNil(message.subject) ? 'Unknown' : message.subject}
        subtitle={`Message Type: ${message.type}`}
        backText="Communications"
        backPath="/Communications"
        isSecondary={true}
        status={
          status && (
            <Box
              className="flex p-2 justify-center items-center rounded-sm"
              bgcolor={campaignMessageBackgroundColor[status]}
              color={campaignMessageTextColor[status]}
            >
              <Typography variant="body-1" fontWeight={'bold'}>
                {status}
              </Typography>
            </Box>
          )
        }
      />
    </Box>
  )
}
