import { useNavBar } from './useNavBar'
import { Content } from './Content'
import LogoImage from 'assets/logo.svg'
import { useLayout } from 'hooks/useLayout'
import { type NavBarRouteDef } from 'types/nav'
import { colors } from 'components/ThemeProvider'
import { APP_BAR_HEIGHT } from 'components/AppBar/AppBar'
import { Box, Divider, Drawer, SvgIcon } from '@mui/material'
import { ReactComponent as ContestsIcon } from 'assets/nav_icons/contests.svg'
import { ReactComponent as DashboardIcon } from 'assets/nav_icons/dashboard.svg'
import { ReactComponent as PromotionsIcon } from 'assets/nav_icons/promotions.svg'
import { ReactComponent as CommunicationsIcon } from 'assets/nav_icons/communications.svg'
import { ReactComponent as RewardsProgramIcon } from 'assets/nav_icons/rewards_program.svg'
import { ReactComponent as PatronAccountsIcon } from 'assets/nav_icons/patron_accounts.svg'
import { ReactComponent as ClientManagementIcon } from 'assets/nav_icons/client_management.svg'
import { ReactComponent as SettingsIcon } from 'assets/nav_icons/settings.svg'
import { DeployVersion } from 'components/DeployVersion'
import { useSideNavCollapseStore } from 'stores/useSideNavCollapseStore'
import React from 'react'

export const NavBarRoutes: Record<string, NavBarRouteDef> = {
  Dashboard: {
    path: '/Home',
    icon: (
      <SvgIcon
        sx={{ color: colors.text.primary, transform: 'translate(0, -2px)' }}
      >
        <DashboardIcon />
      </SvgIcon>
    ),
  },
  'Client Management': {
    path: '/ClientManagement',
    icon: (
      <SvgIcon
        sx={{ color: colors.text.primary, transform: 'translate(0, -2px)' }}
      >
        <ClientManagementIcon />
      </SvgIcon>
    ),
    childrenRoutes: [
      {
        title: 'Licensed Establishments',
        path: '/ClientManagement/LicensedEstablishments',
        icon: null,
        associatedPageUrls: [
          '/LicensedEstablishment/New',
          '/LicensedEstablishments/',
        ],
      },
      {
        title: 'Organizations',
        path: '/ClientManagement/Organizations',
        icon: null,
        associatedPageUrls: ['/Organizations/New', '/Organization/'],
      },
      {
        title: 'Corporate Accounts',
        path: '/ClientManagement/CorporateAccounts',
        icon: null,
        associatedPageUrls: ['/CorporateAccounts/'],
      },
      {
        title: 'J&J Connect Users',
        path: '/ClientManagement/LEConnectUsers',
        icon: null,
        associatedPageUrls: [
          '/ClientManagement/LEConnectUsers/New',
          '/ClientManagement/RolesAndPermissions',
        ],
      },
    ],
  },
  'Patron Accounts': {
    path: '/PatronAccounts',
    icon: (
      <SvgIcon
        sx={{ color: colors.text.primary, transform: 'translate(0, -2px)' }}
      >
        <PatronAccountsIcon />
      </SvgIcon>
    ),
  },
  Rewards: {
    path: '/Rewards',
    icon: (
      <SvgIcon
        sx={{ color: colors.text.primary, transform: 'translate(0, -2px)' }}
      >
        <RewardsProgramIcon />
      </SvgIcon>
    ),
    childrenRoutes: [
      {
        title: 'Reward Programs',
        path: '/Rewards/RewardPrograms',
        icon: null,
        associatedPageUrls: ['/Rewards/RewardPrograms/'],
      },
      {
        title: 'Reward Catalogs',
        path: '/Rewards/RewardCatalogs',
        icon: null,
        associatedPageUrls: ['/Rewards/RewardCatalogs'],
      },
      {
        title: 'Reward Items',
        path: '/Rewards/RewardItems',
        icon: null,
        associatedPageUrls: ['/Rewards/RewardItems'],
      },
    ],
  },
  'Specials & Events': {
    path: '/Promotions',
    icon: (
      <SvgIcon
        sx={{ color: colors.text.primary, transform: 'translate(0, -2px)' }}
      >
        <PromotionsIcon />
      </SvgIcon>
    ),
  },
  Contests: {
    path: '/Contests',
    icon: (
      <SvgIcon
        sx={{ color: colors.text.primary, transform: 'translate(0, -2px)' }}
      >
        <ContestsIcon />
      </SvgIcon>
    ),
    childrenRoutes: [
      {
        title: 'Sweepstakes',
        path: '/Contests/Sweepstakes',
        icon: null,
        associatedPageUrls: ['/Contests/Sweepstakes/'],
      },
      {
        title: 'Passport Games',
        path: '/Contests/PassportGames',
        icon: null,
        associatedPageUrls: ['/Contests/PassportGames/'],
      },
    ],
  },
  Communications: {
    path: '/Communications',
    icon: (
      <SvgIcon
        sx={{ color: colors.text.primary, transform: 'translate(0, -2px)' }}
      >
        <CommunicationsIcon />
      </SvgIcon>
    ),
  },
  Settings: {
    path: '/Settings',
    icon: (
      <SvgIcon
        sx={{ color: colors.text.primary, transform: 'translate(0, -2px)' }}
      >
        <SettingsIcon />
      </SvgIcon>
    ),
  },
}

export const NAVIGATION_BAR_WIDTH = 261

export const NavigationBar = () => {
  const isOpen = useNavBar((state) => state.isOpen)
  const close = useNavBar((state) => state.close)
  const { isMobile } = useLayout()

  const setActiveTab = useSideNavCollapseStore((state) => state.setActiveTab)

  React.useEffect(() => {
    setActiveTab(location.pathname)
  }, [location.pathname, setActiveTab])

  if (isMobile) {
    return (
      <Drawer
        variant="temporary"
        PaperProps={{
          sx: { top: 0 },
        }}
        sx={{
          display: {
            xs: 'block',
            sm: 'block',
            md: 'block',
            lg: 'none',
          },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: isMobile
              ? `${NAVIGATION_BAR_WIDTH * 1.3}px`
              : `${NAVIGATION_BAR_WIDTH}px`,
          },
        }}
        open={isOpen}
        onClose={close}
        data-testid="mobile-navigation-bar"
      >
        <Box m={1} sx={{ ml: 2 }}>
          <img src={LogoImage} width={230} />
        </Box>
        <Divider />
        <Content />
      </Drawer>
    )
  }

  return (
    <Drawer
      variant="permanent"
      PaperProps={{
        sx: { top: `${APP_BAR_HEIGHT}px` },
      }}
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: `${NAVIGATION_BAR_WIDTH}px`,
          height: `calc(100% - ${APP_BAR_HEIGHT}px)`,
          overflowX: 'hidden',
        },
      }}
      open={true}
      data-testid="desktop-navigation-bar"
    >
      <Content />
      <DeployVersion />
    </Drawer>
  )
}
