import AddIcon from '@mui/icons-material/Add'
import { Box, Fab, TextField } from '@mui/material'
import RemoveIcon from '@mui/icons-material/Remove'

export const Counter = ({
  amount,
  setAmount,
  minimumAmount,
  maximumAmount,
}: {
  amount: number
  setAmount: (amount: number) => void
  minimumAmount?: number
  maximumAmount?: number
}) => {
  const increaseAmount = () => setAmount(amount + 1)
  const reduceAmount = () => setAmount(amount - 1)

  return (
    <Box className="flex items-center justify-end space-x-4">
      <Fab
        color="primary"
        aria-label="add"
        size="small"
        onClick={reduceAmount}
        disabled={
          minimumAmount === undefined
            ? false
            : amount === Number(minimumAmount) * -1
        }
      >
        <RemoveIcon />
      </Fab>
      <TextField
        value={amount}
        sx={{ width: '60px', background: 'white' }}
        inputProps={{
          style: {
            padding: '8px 8px',
            textAlign: 'center',
            height: '40px',
          },
        }}
        onChange={(e) =>
          setAmount(Number(e.target.value.replace(/[^-0-9]/g, '')))
        }
      />
      <Fab
        color="primary"
        aria-label="add"
        size="small"
        onClick={increaseAmount}
        disabled={
          maximumAmount === undefined ? false : amount === Number(maximumAmount)
        }
      >
        <AddIcon />
      </Fab>
    </Box>
  )
}
