import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { useMutation, useQueryClient } from '@tanstack/react-query'

interface useDeleteLicensedEstablishmentUserParams {
  id: number | null | undefined
}

interface useDeleteLicensedEstablishmentUserProps {
  onSuccess?: (
    data: undefined,
    variables: useDeleteLicensedEstablishmentUserParams
  ) => void
  onError?: (
    error: Error,
    variables: useDeleteLicensedEstablishmentUserParams
  ) => void
}

export const useDeleteLicensedEstablishmentUserById = ({
  onSuccess,
  onError,
}: useDeleteLicensedEstablishmentUserProps) => {
  const deleteLicensedEstablishmentUser = useTypedApiClient({
    path: '/admin/licensed-establishments/users/{id}',
    method: 'delete',
  })
  const queryClient = useQueryClient()

  return useMutation<any, Error, useDeleteLicensedEstablishmentUserParams>({
    mutationFn: async ({ id }: useDeleteLicensedEstablishmentUserParams) => {
      await deleteLicensedEstablishmentUser({ id: Number(id) })
    },
    onSuccess: async (data, variables) => {
      await queryClient.invalidateQueries({
        queryKey: ['users'],
      })
      onSuccess?.(data, variables)
    },
    onError,
  })
}
