import { type components } from 'src/api/playerPayback/api'
import { PageHeader } from 'components/Shared/PageHeader'
import { Box } from '@mui/material'
import { OrganizationDetailsTabs } from './OrganizationDetailsTabs'
import { isNil } from 'lodash'
import { getLastUpdated } from 'utils/util'

interface OrganizationDetailsHeaderProps {
  organization: components['schemas']['FullOrganizationDTO']
  currentTab: string
}

export const OrganizationDetailsHeader = ({
  organization,
  currentTab,
}: OrganizationDetailsHeaderProps) => {
  const organizationsName: string = isNil(organization.name)
    ? 'Unknown'
    : organization.name

  return (
    <Box>
      <PageHeader
        title={organizationsName}
        subtitle={getLastUpdated(organization.modifiedOn)}
        backText="Organizations"
        backPath="/ClientManagement/Organizations"
        isSecondary={true}
        tabs={
          <OrganizationDetailsTabs
            organizationId={Number(organization.id)}
            currentTab={currentTab}
          />
        }
      />
    </Box>
  )
}
